import { userConstants } from '../_constants';
import { bugsnagClient } from "../_helpers";

let user = JSON.parse(localStorage.getItem('user') as string);
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action: any) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                error: action.error
            };
        case userConstants.GET_USER_REQUEST:
            return {
                loading: true
            };
        case userConstants.GET_USER_SUCCESS:
            bugsnagClient.user = {
                id: action.user.cms_user_id,
                name: action.user.cms_name,
                email: action.user.email
            };
            return {
                user: action.user
            };
        case userConstants.GET_USER_FAILURE:
            return {
                error: action.error
            };
        case userConstants.LOGOUT_REQUEST:
        case userConstants.LOGOUT_SUCCESS:
        case userConstants.LOGOUT_FAILURE:
            return {};
        default:
            return state
    }
}