export const jobConstants = {
    SAVE_REQUEST: 'JOBS_SAVE_REQUEST',
    SAVE_SUCCESS: 'JOBS_SAVE_SUCCESS',
    SAVE_FAILURE: 'JOBS_SAVE_FAILURE',

    SYNC_REQUEST: 'JOBS_SYNC_REQUEST',
    SYNC_SUCCESS: 'JOBS_SYNC_SUCCESS',
    SYNC_FAILURE: 'JOBS_SYNC_FAILURE',

    GETALL_REQUEST: 'JOBS_GETALL_REQUEST',
    GETALL_SUCCESS: 'JOBS_GETALL_SUCCESS',
    GETALL_FAILURE: 'JOBS_GETALL_FAILURE',

    GETCALENDAR_REQUEST: 'JOBS_GETCALENDAR_REQUEST',
    GETCALENDAR_SUCCESS: 'JOBS_GETCALENDAR_SUCCESS',
    GETCALENDAR_FAILURE: 'JOBS_GETCALENDAR_FAILURE',
    
    GETONE_REQUEST: 'JOBS_GETONE_REQUEST',
    GETONE_SUCCESS: 'JOBS_GETONE_SUCCESS',
    GETONE_FAILURE: 'JOBS_GETONE_FAILURE',

    JOB_STATUS_NEW: 'new',
    JOB_STATUS_OPEN: 'open',
    JOB_STATUS_QUEUED: 'queued',
    JOB_STATUS_FAILED: 'failed',
    JOB_STATUS_COMPLETE: 'complete',
    JOB_STATUS_SKIPPED: 'skipped',

    TASK_STATUS_NEW: 'new',
    TASK_STATUS_OPEN: 'open',
    TASK_STATUS_QUEUED: 'queued',
    TASK_STATUS_FAILED: 'failed',
    TASK_STATUS_COMPLETE: 'complete',
    TASK_STATUS_SKIPPED: 'skipped'
};

export function mapJobStatus(key: string) {
    const statuses: any = [];

    statuses[jobConstants.JOB_STATUS_NEW]         = { icon: 'fa-certificate', name: 'New' };
    statuses[jobConstants.JOB_STATUS_OPEN]        = { icon: 'fa-circle-o', name: 'Open' };
    statuses[jobConstants.JOB_STATUS_QUEUED]      = { icon: 'fa-clock-o', name: 'Queued' };
    statuses[jobConstants.JOB_STATUS_FAILED]      = { icon: 'fa-ban', name: 'Failed' };
    statuses[jobConstants.JOB_STATUS_COMPLETE]    = { icon: 'fa-check-circle-o', name: 'Complete' };
    statuses[jobConstants.JOB_STATUS_SKIPPED]    = { icon: 'fa-fast-forward', name: 'Skipped' };

    key = key.toLowerCase();

    if (key in statuses) {
        return statuses[key];
    }
    return false;
}

export function mapTaskStatus(key: string) {
    const statuses: any = [];

    statuses[jobConstants.TASK_STATUS_NEW]         = { icon: 'fa-certificate', name: 'New' };
    statuses[jobConstants.TASK_STATUS_OPEN]        = { icon: 'fa-circle-o', name: 'Open' };
    statuses[jobConstants.TASK_STATUS_QUEUED]      = { icon: 'fa-clock-o', name: 'Queued' };
    statuses[jobConstants.TASK_STATUS_FAILED]      = { icon: 'fa-ban', name: 'Failed' };
    statuses[jobConstants.TASK_STATUS_COMPLETE]    = { icon: 'fa-check-circle-o', name: 'Complete' };
    statuses[jobConstants.TASK_STATUS_SKIPPED]    = { icon: 'fa-fast-forward', name: 'Skipped' };

    key = key.toLowerCase();

    if (key in statuses) {
        return statuses[key];
    }
    return false;
}