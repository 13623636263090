export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

    GET_USER_REQUEST: 'USERS_GET_REQUEST',
    GET_USER_SUCCESS: 'USERS_GET_SUCCESS',
    GET_USER_FAILURE: 'USERS_GET_FAILURE',
};
