import React from 'react';

import './index.scss';

interface BoxProps {
    title?: string,
    icon?: any,
    theme?: string
}

export class Box extends React.Component<BoxProps, any> {

    render() {
        let { title, icon, theme, children } = this.props;
        return (
            <div className={`Box ${theme}`}>
                {title && (
                    <div className="BoxTitle">
                        {icon && <i className={`fa ${icon}`} />}
                        {title}
                    </div>
                )}
                <div className="BoxContent">
                    {children}
                </div>
            </div>
        );
    }
}