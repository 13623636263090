export const fileSystemConstants = {
    ACCESS_REQUEST: 'FS_ACCESS_REQUEST',
    ACCESS_SUCCESS: 'FS_ACCESS_SUCCESS',
    ACCESS_FAILURE: 'FS_ACCESS_FAILURE',

    QUOTA_REQUEST: 'FS_QUOTA_REQUEST',
    QUOTA_SUCCESS: 'FS_QUOTA_SUCCESS',
    QUOTA_FAILURE: 'FS_QUOTA_FAILURE',

    GET_FILE_REQUEST: 'FS_GETFILE_REQUEST',
    GET_FILE_SUCCESS: 'FS_GETFILE_SUCCESS',
    GET_FILE_FAILURE: 'FS_GETFILE_FAILURE',

    GET_FILES_REQUEST: 'FS_GETFILES_REQUEST',
    GET_FILES_SUCCESS: 'FS_GETFILES_SUCCESS',
    GET_FILES_FAILURE: 'FS_GETFILES_FAILURE',

    PUT_FILE_REQUEST: 'FS_GETFILE_REQUEST',
    PUT_FILE_SUCCESS: 'FS_GETFILE_SUCCESS',
    PUT_FILE_FAILURE: 'FS_GETFILE_FAILURE',

    FLUSH_REQUEST: 'FS_FLUSH_REQUEST',
    FLUSH_SUCCESS: 'FS_FLUSH_SUCCESS',
    FLUSH_FAILURE: 'FS_FLUSH_FAILURE',

    DUMPRAW_REQUEST: 'FS_DUMPRAW_REQUEST',
    DUMPRAW_SUCCESS: 'FS_DUMPRAW_SUCCESS',
    DUMPRAW_FAILURE: 'FS_DUMPRAW_FAILURE'
};
