import {fileSystemConstants} from '../_constants';
import { fileSystemService } from '../_services';
import {alertActions, jobActions} from './';
import { history } from '../_helpers';

export const fileSystemActions = {
    requestAccess,
    requestRemainingStorage,
    getFile,
    getAll,
    putFile,
    flush,
    dumpRaw
};

function requestAccess() {
    return (dispatch: any) => {
        dispatch(request());
        fileSystemService.requestAccess()
            .then((fs: any) => {
                dispatch(success(fs));
                dispatch(requestRemainingStorage());
            })
            .catch((error: any) => {
                dispatch(failure(typeof error === 'string' ? error : error.message));
                dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
            });
    };

    function request() { return { type: fileSystemConstants.ACCESS_REQUEST } }
    function success(fs: any) { return { type: fileSystemConstants.ACCESS_SUCCESS, fs } }
    function failure(error: any) { return { type: fileSystemConstants.ACCESS_FAILURE, error } }
}

function requestRemainingStorage() {
    return (dispatch: any) => {
        dispatch(request());
        fileSystemService.requestRemainingStorage()
            .then((res: any) => dispatch(success(res.total, res.used)))
            .catch((error: any) => {
                dispatch(failure(typeof error === 'string' ? error : error.message));
                dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
            });
    };

    function request() { return { type: fileSystemConstants.QUOTA_REQUEST } }
    function success(total: any, used: any) { return { type: fileSystemConstants.QUOTA_SUCCESS, total, used } }
    function failure(error: any) { return { type: fileSystemConstants.QUOTA_FAILURE, error } }
}

function getFile(path: any) {
    return (dispatch: any) => {
        dispatch(request());
        fileSystemService.getFile(path)
            .then((file: any) => dispatch(success(file)))
            .catch((error: any) => {
                dispatch(failure(typeof error === 'string' ? error : error.message));
                dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
            });
    };

    function request() { return { type: fileSystemConstants.GET_FILE_REQUEST } }
    function success(file: any) { return { type: fileSystemConstants.GET_FILE_SUCCESS, file } }
    function failure(error: any) { return { type: fileSystemConstants.GET_FILE_FAILURE, error } }
}

function getAll(prefix: any = undefined) {
    return (dispatch: any) => {
        dispatch(request());
        fileSystemService.getAll(prefix)
            .then((files: any) => dispatch(success(files)))
            .catch((error: any) => {
                dispatch(failure(typeof error === 'string' ? error : error.message));
                dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
            });
    };

    function request() { return { type: fileSystemConstants.GET_FILES_REQUEST } }
    function success(files: any) { return { type: fileSystemConstants.GET_FILES_SUCCESS, files } }
    function failure(error: any) { return { type: fileSystemConstants.GET_FILES_FAILURE, error } }
}

function putFile(path: any, content: any, createOnly: any, encoding: any = 'application/json') {
    return (dispatch: any) => {
        dispatch(request());
        fileSystemService.putFile(path, content, createOnly, encoding)
            .then(() => dispatch(success()))
            .catch((error: any) => {
                dispatch(failure(typeof error === 'string' ? error : error.message));
                dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
            });
    };

    function request() { return { type: fileSystemConstants.PUT_FILE_REQUEST } }
    function success() { return { type: fileSystemConstants.PUT_FILE_SUCCESS } }
    function failure(error: any) { return { type: fileSystemConstants.PUT_FILE_FAILURE, error } }
}

function flush() {
    return (dispatch: any) => {
        dispatch(request());
        fileSystemService.downloadRaw().then(() => {
            fileSystemService.flush()
                .then(() => {
                    dispatch(success());
                    dispatch(jobActions.sync(true));
                })
                .catch((error: any) => {
                    dispatch(failure(typeof error === 'string' ? error : error.message));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                });
        });
    };

    function request() { return { type: fileSystemConstants.FLUSH_REQUEST } }
    function success() { return { type: fileSystemConstants.FLUSH_SUCCESS } }
    function failure(error: any) { return { type: fileSystemConstants.FLUSH_FAILURE, error } }
}

function dumpRaw() {
    return (dispatch: any) => {
        dispatch(request());
        fileSystemService.dumpRaw()
            .then(() => {
                dispatch(success());
            })
            .catch((error: any) => {
                dispatch(failure(typeof error === 'string' ? error : error.message));
                dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
            });
    };

    function request() { return { type: fileSystemConstants.DUMPRAW_REQUEST } }
    function success() { return { type: fileSystemConstants.DUMPRAW_SUCCESS } }
    function failure(error: any) { return { type: fileSystemConstants.DUMPRAW_FAILURE, error } }
}