import { authHeader } from '../_helpers';
import { handleResponse, fileSystemService } from '../_services';

let w = (window as any);

export const siteService = {
    getRandoms,
    getBulkRandoms,
    updateRandoms,
    flushRandoms,
    getFailureLogs,
    getBulkFailureLogs,
    flushFailureLogs,
    getWorkSchedule,
    flushWorkSchedule,
    getBulkWorkSchedule
};

function getRandoms(site_id: any, force: any = false) {
    return new Promise((resolve: any, reject: any) => {
        if (force) {
            flushRandoms(site_id).then(() => {
                getRandoms(site_id);
            }).catch(() => {
                resolve([]);
            });
        } else {
            // Attempt to get the local version.
            fileSystemService.getFile(`site_randoms_${site_id}.json`).then((file: any) => {
                resolve(file);
            }).catch((error: any) => {
                // If it doesn't exist, check if we are online.
                if (w.navigator.onLine) {
                    // Pull the online version.
                    const requestOptions: any = {
                        method: 'GET',
                        headers: authHeader()
                    };
                    fetch(`/api/sites/${site_id}/randoms?all`, requestOptions).then(handleResponse).then((randoms: any) => {
                        return fileSystemService.putFile(`site_randoms_${site_id}.json`, randoms, false);
                    }).then(resolve).catch(() => {
                        resolve([]);
                    })
                } else {
                    resolve([]);
                }
            });
        }
    });

}

function getFailureLogs(site_id: any, force: any = false) {
    return new Promise((resolve: any, reject: any) => {
        if (force) {
            flushFailureLogs(site_id).then(() => {
                getFailureLogs(site_id);
            }).catch(() => {
                resolve([]);
            });
        } else {
            // Attempt to get the local version.
            fileSystemService.getFile(`site_failurelogs_${site_id}.json`).then((file: any) => {
                resolve(file);
            }).catch((error: any) => {
                // If it doesn't exist, check if we are online.
                if (w.navigator.onLine) {
                    // Pull the online version.
                    const requestOptions: any = {
                        method: 'GET',
                        headers: authHeader()
                    };
                    fetch(`/api/sites/${site_id}/logs?all`, requestOptions).then(handleResponse).then((logs: any) => {
                        return fileSystemService.putFile(`site_failurelogs_${site_id}.json`, logs, false);
                    }).then(resolve).catch(() => {
                        resolve([]);
                    })
                } else {
                    resolve([]);
                }
            });
        }
    });

}

function flushWorkSchedule(site_id: any) {
    return new Promise((resolve: any, reject: any) => {
        site_id = (typeof site_id === 'number' ? [site_id] : site_id);
        let actions: any = [];
        site_id.forEach((id: any) => {
            actions.push(fileSystemService.removeFile(`site_schedule_${id}.json`))
        });
        // Attempt to get the local version.
        Promise.all(actions).then(resolve).catch(reject);
    });
}

function getWorkSchedule(site_id: any, force: any = false) {
    return new Promise((resolve: any, reject: any) => {
        if (force) {
            flushWorkSchedule(site_id).then(() => {
                getWorkSchedule(site_id);
            }).catch(() => {
                resolve([]);
            });
        } else {
            // Attempt to get the local version.
            fileSystemService.getFile(`site_schedule_${site_id}.json`).then((file: any) => {
                resolve(file);
            }).catch((error: any) => {
                // If it doesn't exist, check if we are online.
                if (w.navigator.onLine) {
                    // Pull the online version.
                    const requestOptions: any = {
                        method: 'GET',
                        headers: authHeader()
                    };
                    fetch(`/api/sites/${site_id}/schedule`, requestOptions).then(handleResponse).then((tasks: any) => {
                        return fileSystemService.putFile(`site_schedule_${site_id}.json`, tasks, false);
                    }).then(resolve).catch(() => {
                        resolve([]);
                    })
                } else {
                    resolve([]);
                }
            });
        }
    });

}

function getBulkRandoms(site_ids: any, force: any = false) {
    return new Promise((resolve: any, reject: any) => {
        if (site_ids.length === 0) {
            return resolve([]);
        }
        if (force) {
            flushRandoms(site_ids).then(() => {
                getBulkRandoms(site_ids);
            }).catch(reject);
        } else {

            let actions: any = [];
            site_ids.forEach((site_id: any) => {
                actions.push(new Promise((_resolve: any) => {
                    // get already stored randoms. If it doesn't exist, queue it to be retrieved.
                    fileSystemService.getFile(`site_randoms_${site_id}.json`).then((file: any) => {
                        _resolve(file);
                    }).catch(() => {
                        _resolve(site_id);
                    })
                }));
            });

            Promise.all(actions).then((randoms: any) => {
                let existing: any = randoms.filter((random: any) => typeof random !== 'number'),
                    to_pull: any = randoms.filter((random: any) => typeof random === 'number');

                if (to_pull && w.navigator.onLine) {
                    // Pull the online version.
                    const requestOptions: any = {
                        method: 'POST',
                        headers: authHeader(),
                        body: {
                            site_ids: to_pull,
                            all: 1
                        }
                    };
                    fetch(`/api/sites/randoms`, requestOptions).then(handleResponse).then((randoms: any) => {
                        let groups: any = {};
                        randoms.forEach((random: any) => {
                            if (!(random.site_id in groups)) {
                                groups[random.site_id] = [];
                            }
                            groups[random.site_id].push(random);
                        });

                        actions = [];

                        Object.keys(groups).forEach((site_id: any) => {
                            actions.push(fileSystemService.putFile(`site_randoms_${site_id}.json`, groups[site_id], false));
                        });

                        Promise.all(actions).then((randoms) => {
                            existing = existing.concat(randoms);
                            resolve(existing);
                        }).catch(() => {
                            resolve([]);
                        });

                    }).catch(() => {
                        resolve([]);
                    });
                } else {
                    resolve([]);
                }
            });

        }
    });

}

function getBulkWorkSchedule(site_ids: any, force: any = false) {
    return new Promise((resolve: any, reject: any) => {
        if (site_ids.length === 0) {
            return resolve([]);
        }
        if (force) {
            flushWorkSchedule(site_ids).then(() => {
                getBulkWorkSchedule(site_ids);
            }).catch(reject);
        } else {

            let actions: any = [];
            site_ids.forEach((site_id: any) => {
                actions.push(new Promise((_resolve: any) => {
                    // get already stored randoms. If it doesn't exist, queue it to be retrieved.
                    fileSystemService.getFile(`site_schedule_${site_id}.json`).then((file: any) => {
                        _resolve(file);
                    }).catch(() => {
                        _resolve(site_id);
                    })
                }));
            });

            Promise.all(actions).then((schedule: any) => {
                let existing: any = schedule.filter((schedule: any) => typeof schedule !== 'number'),
                    to_pull: any = schedule.filter((schedule: any) => typeof schedule === 'number');

                if (to_pull && w.navigator.onLine) {
                    // Pull the online version.
                    const requestOptions: any = {
                        method: 'POST',
                        headers: authHeader(),
                        body: {
                            site_ids: to_pull,
                            all: 1
                        }
                    };
                    fetch(`/api/sites/schedule`, requestOptions).then(handleResponse).then((schedules: any) => {

                        actions = [];

                        Object.keys(schedules).forEach((site_id: any) => {
                            actions.push(fileSystemService.putFile(`site_schedule_${site_id}.json`, schedules[site_id], false));
                        });

                        Promise.all(actions).then((schedules) => {
                            existing = existing.concat(schedules);
                            resolve(existing);
                        }).catch(() => {
                            resolve([]);
                        });

                    }).catch(() => {
                        resolve([]);
                    });
                } else {
                    resolve([]);
                }
            });

        }
    });

}

function getBulkFailureLogs(site_ids: any, force: any = false) {
    return new Promise((resolve: any, reject: any) => {
        if (site_ids.length === 0) {
            return resolve([]);
        }
        if (force) {
            flushFailureLogs(site_ids).then(() => {
                getBulkFailureLogs(site_ids);
            }).catch(reject);
        } else {

            let actions: any = [];
            site_ids.forEach((site_id: any) => {
                actions.push(new Promise((_resolve: any) => {
                    // get already stored logs. If it doesn't exist, queue it to be retrieved.
                    fileSystemService.getFile(`site_failurelogs_${site_id}.json`).then((file: any) => {
                        _resolve(file);
                    }).catch(() => {
                        _resolve(site_id);
                    })
                }));
            });

            Promise.all(actions).then((logs: any) => {
                let existing: any = logs.filter((log: any) => typeof log !== 'number'),
                    to_pull: any = logs.filter((log: any) => typeof log === 'number');

                if (to_pull && w.navigator.onLine) {
                    // Pull the online version.
                    const requestOptions: any = {
                        method: 'POST',
                        headers: authHeader(),
                        body: {
                            site_ids: to_pull,
                            all: 1
                        }
                    };
                    fetch(`/api/sites/logs`, requestOptions).then(handleResponse).then((logs: any) => {
                        let groups: any = {};
                        logs.forEach((log: any) => {
                            if (!(log.site_id in groups)) {
                                groups[log.site_id] = [];
                            }
                            groups[log.site_id].push(log);
                        });

                        actions = [];

                        Object.keys(groups).forEach((site_id: any) => {
                            actions.push(fileSystemService.putFile(`site_failurelogs_${site_id}.json`, groups[site_id], false));
                        });

                        Promise.all(actions).then((logs) => {
                            existing = existing.concat(logs);
                            resolve(existing);
                        }).catch(() => {
                            resolve([]);
                        });

                    }).catch(() => {
                        resolve([]);
                    });
                } else {
                    resolve([]);
                }
            });

        }
    });

}

function updateRandoms(site_id: any, randoms: any) {
    return new Promise((resolve: any, reject: any) => {
        // Attempt to get the local version.
        site_id = (typeof site_id === 'number' ? {[site_id]: randoms} : site_id);
        let actions: any = [];
        Object.keys(site_id).forEach((id: any) => {
            actions.push(fileSystemService.putFile(`site_randoms_${id}.json`, site_id[id], false))
        });
        Promise.all(actions).then(resolve).catch(reject);
    });
}

function flushRandoms(site_id: any) {
    return new Promise((resolve: any, reject: any) => {
        site_id = (typeof site_id === 'number' ? [site_id] : site_id);
        let actions: any = [];
        site_id.forEach((id: any) => {
            actions.push(fileSystemService.removeFile(`site_randoms_${id}.json`))
        });
        // Attempt to get the local version.
        Promise.all(actions).then(resolve).catch(reject);
    });
}

function flushFailureLogs(site_id: any) {
    return new Promise((resolve: any, reject: any) => {
        site_id = (typeof site_id === 'number' ? [site_id] : site_id);
        let actions: any = [];
        site_id.forEach((id: any) => {
            actions.push(fileSystemService.removeFile(`site_failurelogs_${id}.json`))
        });
        // Attempt to get the local version.
        Promise.all(actions).then(resolve).catch(reject);
    });
}