import { authHeader, bugsnagClient } from '../_helpers';
import { handleResponse, fileSystemService } from '../_services';
import { jobConstants } from '../_constants';
import moment from 'moment';

let w = (window as any);
w.requestFileSystem = w.requestFileSystem || w.webkitRequestFileSystem;

function prepareJob(job: any) {
    return job;
}

function prepareBody(job: any) {
    return new Promise((resolve: any, reject: any) => {

        let prom_tasks: any = [];

        Object.keys(job.tasks).map((task_id: any) => {

            ((_task_id: any) => {
                let task: any = job.tasks[_task_id];
                prom_tasks.push(new Promise((_resolve: any, _reject: any) => {
                    let prom_photos: any = [];

                    if (task.photos && task.photos.length) {
                        task.photos.forEach((photo: any) => {
                            ((_photo: any) => {
                                prom_photos.push(new Promise((__resolve: any, __reject: any) => {
                                    if (_photo.image.indexOf('data') > -1 || _photo.image.indexOf('http') > -1) {
                                        __resolve(photo);
                                    } else {
                                        fileSystemService.getFile(photo.image).then((content: any) => {
                                            _photo.image = content;
                                            __resolve(photo);
                                        }).catch(__reject);
                                    }
                                }));
                            })(photo);
                        });
                    } else {
                        prom_photos.push(Promise.resolve(false));
                    }

                    Promise.all(prom_photos).then((results: any) => {

                        if (results.length === 1 && results[0] === false) {
                            results = [];
                        }

                        let tests = task.tests.filter((v: any) => v).map((test: any) => {
                            return {
                                id: test.id,
                                passfail: test.passfail,
                                value: test.value,
                                comments: test.comments,
                                test_id: test.test_id
                            };
                        });

                        _resolve({
                            id: task.id,
                            unit: task.unit,
                            status: task.status,
                            comments: task.comments,
                            started_at: (task.started_at ? moment(task.started_at).format('YYYY-MM-DD HH:mm:ss') : null),
                            completed_at: (task.started_at ? moment(task.completed_at).format('YYYY-MM-DD HH:mm:ss') : null),
                            photos: results,
                            tests: tests,
                        });

                    });
                }));
            })(task_id);

        });

        Promise.all(prom_tasks).then((tasks: any) => {
            resolve({
                status: job.status,
                started_at: (job.started_at ? moment(job.started_at).format('YYYY-MM-DD HH:mm:ss') : null),
                completed_at: (job.completed_at ? moment(job.completed_at).format('YYYY-MM-DD HH:mm:ss') : null),
                comments: job.comments,
                internal_notes: job.internal_notes,
                attachment: (job.internal_notes_attachment && job.internal_notes_attachment.attachment ? job.internal_notes_attachment.attachment : undefined),
                tasks: tasks,
                random_units: job.random_units,
                log_items: ('log_items' in job ? job.log_items : []),
                signed_by: job.signed_by,
                signature: job.signature,
                engineer_signature: job.engineer_signature
            });
        });

    });
}

export const jobService = {
    save,
    sync,
    getOne,
    getAll,
    getCalendar
};

function save(job: any) {
    // check if online... if so, upload and report back.
    // if offline, save in local storage/file system.
    return new Promise((resolve: any, reject: any) => {
        job.send_on_next_sync = true;
        bugsnagClient.leaveBreadcrumb('Saving Job', {
            job_id: job.id,
            status: job.status
        });
        return fileSystemService.putFile(`job_${job.id}.json`, job, false).then((job: any) => {
            bugsnagClient.leaveBreadcrumb('Job saved locally');
            if (w.navigator.onLine && job.status !== jobConstants.JOB_STATUS_NEW) {
                bugsnagClient.leaveBreadcrumb('Online, attempting upload');
                if (job.status === jobConstants.JOB_STATUS_QUEUED) {
                    job.status = ('skipping_job' in job && job.skipping_job ? jobConstants.JOB_STATUS_SKIPPED : jobConstants.JOB_STATUS_COMPLETE);
                    job.recently_saved = true;
                    // We've completed the job, now send it up!
                }
                prepareBody(job).then((body: any) => {

                    body = JSON.stringify(body);
                    bugsnagClient.leaveBreadcrumb('Job JSON Length: ' + (body ? body.length : 0));
                    if (!body || body.length === 0) {
                        bugsnagClient.notify('Invalid body...');
                    }

                    const requestOptions: any = {
                        method: 'PUT',
                        headers: Object.assign({}, authHeader(), {
                            'Content-Type': 'application/json'
                        }),
                        body: body
                    };
                    fetch(`/api/jobs/${job.id}`, requestOptions).then(handleResponse).then((result: any) => {
                        if (result && result.id == job.id) {
                            bugsnagClient.leaveBreadcrumb('Uploaded successfully');
                            job.send_on_next_sync = false;
                            fileSystemService.putFile(`job_${job.id}.json`, job, false).then((job: any) => {
                                return resolve(job);
                            });
                        } else {
                            bugsnagClient.leaveBreadcrumb('Failed to upload, invalid response.');
                            reject('Failed to upload job, please sync.');
                        }
                    }).catch((error: any) => {
                        bugsnagClient.leaveBreadcrumb('Failed to upload job, queuing for next run and moving on.');
                        bugsnagClient.notify(error);
                        job.send_on_next_sync = true;
                        fileSystemService.putFile(`job_${job.id}.json`, job, false).then((job: any) => {
                            return resolve(job);
                        }).catch((error: any) => {
                            bugsnagClient.notify(error);
                            reject(error);
                        })
                    });

                }).catch((error: any) => {
                    bugsnagClient.notify(error);
                    reject(error);
                });
            } else {
                return resolve(job);
            }
        }).catch(reject);
    });
}

function getOne(job_id: any) {
    return new Promise((resolve: any, reject: any) => {
        // Attempt to get the local version.
        fileSystemService.getFile(`job_${job_id}.json`).then((file: any) => {
            resolve(file);
        }).catch(() => {
            reject('The requested job has not been downloaded to your tablet.');
        });
    });
}

function getAll() {
    return new Promise((resolve: any, reject: any) => {
        bugsnagClient.leaveBreadcrumb('Getting all jobs');
        // Attempt to get the local version.
        fileSystemService.getAll('job_.*\.json').then((files: any) => {
            resolve(files);
        }).catch((error: any) => {
            bugsnagClient.leaveBreadcrumb('No local jobs exist, trying online');
            // If it doesn't exist, check if we are online.
            if (w.navigator.onLine) {
                // Pull the online version.
                const requestOptions: any = {
                    method: 'GET',
                    headers: authHeader()
                };
                fetch(`/api/jobs?include=tasks,site&per_page=10000`, requestOptions).then(handleResponse).then((jobs: any) => {
                    let proms:any = [];
                    jobs.forEach((job: any) => {
                        if (typeof job === 'object' && job !== null) {
                            proms.push(fileSystemService.putFile(`job_${job.id}.json`, prepareJob(job), true));
                        }
                    });
                    return Promise.all(proms);
                }).then(resolve).catch((error: any) => {
                    bugsnagClient.notify(error);
                    reject(error);
                });
            } else {
                bugsnagClient.notify(error);
                resolve([]);
            }
        });
    });
}

function getCalendar(force: any = false) {
    return new Promise((resolve: any, reject: any) => {
        let proms: any = [Promise.resolve()];
        if (force) {
            bugsnagClient.leaveBreadcrumb('Forcing removal of local calendar');
            proms.push(fileSystemService.removeFile('calendar.json'));
        }
        Promise.all(proms).then(() => {
            bugsnagClient.leaveBreadcrumb('Getting calendar');
            // Attempt to get the local version.
            fileSystemService.getFile('calendar.json').then((file: any) => {
                resolve(file);
            }).catch((error: any) => {
                bugsnagClient.leaveBreadcrumb('No local calendar exists, trying online');
                // If it doesn't exist, check if we are online.
                if (w.navigator.onLine) {
                    // Pull the online version.
                    const requestOptions: any = {
                        method: 'GET',
                        headers: authHeader()
                    };
                    fetch(`/api/jobs/calendar`, requestOptions).then(handleResponse).then((calendar_items: any) => {
                        return fileSystemService.putFile('calendar.json', calendar_items, false);
                    }).then(resolve).catch((error: any) => {
                        bugsnagClient.notify(error);
                        reject(error);
                    });
                } else {
                    bugsnagClient.notify(error);
                    resolve([]);
                }
            });
        });
    });
}

function sync(force: any = false) {
    bugsnagClient.leaveBreadcrumb('Synchronising jobs!');
    if (!('initial_sync' in w)) {
        force = true;
        w.initial_sync = true;
        bugsnagClient.leaveBreadcrumb('Forcing online sync');
    }
    return new Promise((resolve: any, reject: any) => {

        bugsnagClient.leaveBreadcrumb('Getting all local jobs');
        // Get all local jobs.
        fileSystemService.getAll('job_').then((jobs: any) => {

            // If we're not online, just return what we have.
            if (!w.navigator.onLine) {
                return resolve(jobs);
            }

            let toSend: any = [],
                queued: any = 0;

            // Loop through all jobs which are queued.
            jobs.forEach((job: any) => {
                if (typeof job === 'object' && job !== null) {
                    if ('send_on_next_sync' in job && job.send_on_next_sync) {
                        bugsnagClient.leaveBreadcrumb('Job queued for send on next sync');
                        queued++;
                        toSend.push(save(job));
                    } else {
                        toSend.push(Promise.resolve(job));
                    }
                }
            });

            if (queued <= 0 && (!force && jobs.length > 0)) {
                return resolve(jobs);
            }
            bugsnagClient.leaveBreadcrumb('Uploading jobs');

            Promise.all(toSend).then((sentJobs: any) => {
                // Pull the online version.
                const requestOptions: any = {
                    method: 'GET',
                    headers: authHeader()
                };
                bugsnagClient.leaveBreadcrumb('Downloading jobs');
                fetch(`/api/jobs?include=tasks,site&per_page=10000`, requestOptions).then(handleResponse).then((newJobs: any) => {

                    bugsnagClient.leaveBreadcrumb('Preparing new jobs');
                    newJobs = newJobs.map((job: any) => {
                        return prepareJob(job);
                    });

                    let proms:any = []; //sentJobs.map((job: any) => Promise.resolve(job));
                    let job_ids: any = newJobs.map((job: any) => {
                        return job.id;
                    });
                    // Loop through the list.
                    newJobs.forEach((job: any) => {
                        if (typeof job === 'object' && job !== null) {
                            // Check if the job already exists.
                            if (jobs.filter((j: any) => j.id === job.id).length === 0) {
                                bugsnagClient.leaveBreadcrumb('Storing new job');
                                proms.push(fileSystemService.putFile(`job_${job.id}.json`, job, true));
                            } else {
                                bugsnagClient.leaveBreadcrumb('Updating existing job');
                                let tmpJob = jobs.find((_j: any) => _j.id === job.id),
                                    newJob = merge_jobs(tmpJob, job);

                                proms.push(fileSystemService.putFile(`job_${job.id}.json`, newJob, false));
                            }
                        }
                    });
                    // Remove any old jobs no longer in the list.
                    jobs.forEach((job: any) => {
                        if (typeof job === 'object' && job !== null) {
                            if (newJobs.filter((j: any) => j.id === job.id).length === 0) {
                                bugsnagClient.leaveBreadcrumb('Removing old job');
                                fileSystemService.getAll('photo_' + job.id + '_', true).then((files: any) => {
                                    files.forEach((file: any) => {
                                        proms.push(fileSystemService.removeFile(file));
                                    });
                                });
                                proms.push(fileSystemService.removeFile(`job_${job.id}.json`));
                            }
                        }
                    });

                    fileSystemService.getAll('photo_', true).then((files: any) => {
                        files.forEach((file: any) => {
                            let job_id = file.split('_')[1];
                            if (job_ids.indexOf(job_id) === -1) {
                                bugsnagClient.leaveBreadcrumb('Removing left over images');
                                proms.push(fileSystemService.removeFile(file));
                            }
                        });
                    });

                    bugsnagClient.leaveBreadcrumb('Storing last sync date');
                    localStorage.setItem('last_sync_date', moment().format('DD/MM/YYYY HH:mm'));
                    //
                    return Promise.all(proms).catch((error: any) => {
                        bugsnagClient.notify(error);
                        reject(error);
                    });
                }).then(resolve).catch((error: any) => {
                    bugsnagClient.notify(error);
                    reject(error);
                });
            });
        }).catch((err: any) => {
            bugsnagClient.notify(err);
        });

    });
}

function merge_jobs(local: any, server: any) {

    const job_fields = ['route', 'route_colour', 'route_id', 'job_type', 'site', 'week_commencing', 'job_reference'];
    const task_fields = ['unit', 'photos'];
    const test_fields = ['group', 'metric', 'name', 'pass_lower', 'pass_upper', 'short_name', 'test_id', 'unit'];

    job_fields.forEach((field: any) => {
        local[field] = server[field];
    });

    let tasks: any = [];

    local.log_items = [];

    server.tasks.forEach((task: any) => {

        let localTask = local.tasks.find((t: any) => (task && t && t.id === task.id));
        // If the task doesn't exist, use the server task.
        if (!localTask) {
            localTask = task;
        }
        task_fields.forEach((field: any) => {
            localTask[field] = task[field];
        });

        let tests: any = [];

        task.tests.forEach((test: any) => {

            let newTest = localTask.tests.find((t: any) => (t && test && t.id === test.id));
            // If the test doesn't exist, use the server task.
            if (!newTest) {
                newTest = test;
            }
            test_fields.forEach((field: any) => {
                newTest[field] = test[field];
            });

            tests.push(newTest);

        });

        localTask.tests.forEach((local_test: any) => {

            if (local_test && 'failure_test' in local_test && local_test.failure_test > 0 && local_test.id < 0) {

                // We have a local test. Now find if we have already saved this and now have duplicates.
                let exists: any = false,
                    test_idx: any = -1;
                tests.forEach((server_test: any, idx: any) => {

                    if (server_test.failure_test > 0 && server_test.failure_test == local_test.failure_test) {
                        exists = true;
                    }

                    if (server_test.id == Math.abs(local_test.id)) {
                        test_idx = idx;
                    }

                });

                if (!exists && test_idx > -1) {
                    tests.splice(test_idx+1, 0, local_test);
                }

            }

        });

        localTask.tests = tests;

        tasks.push(localTask);

    });

    local.tasks = tasks;

    return local;


}