import {authHeader, store, bugsnagClient} from '../_helpers';
import {handleResponse} from './index';
import moment from 'moment';

export const fileSystemService = {
    requestAccess,
    requestRemainingStorage,
    getFile,
    getAll,
    putFile,
    removeFile,
    flush,
    dumpRaw,
    downloadRaw
};

let w = (window as any);
w.requestFileSystem = w.requestFileSystem || w.webkitRequestFileSystem;
w.navigator.PersistentStorage = w.navigator.PersistentStorage || w.navigator.webkitPersistentStorage;

const minStorage = 512*1024*1024;

function requestRemainingStorage() {
    return new Promise((resolve: any, reject: any) => {
        const { fileSystem }: any = store.getState();
        w.navigator.PersistentStorage.queryUsageAndQuota((used: any, total: any) => {
            resolve({
                total,
                used
            });
        }, (error: any) => {
            bugsnagClient.notify(error);
            reject(error);
        });
    });
}

function requestAccess() {
    return new Promise((resolve: any, reject: any) => {

        bugsnagClient.leaveBreadcrumb('Requesting FS Access');

        const { fileSystem }: any = store.getState();
        // if (fileSystem.ready) {
        //     return resolve(fileSystem.fs);
        // }

        w.navigator.PersistentStorage.queryUsageAndQuota((used: any, remaining: any) => {
            bugsnagClient.leaveBreadcrumb('Querying FS Usage/Quota');
            let prom = Promise.resolve(minStorage);
            if ((remaining - used) < 1024 || remaining < minStorage) {
                prom = new Promise((_resolve: any, _reject: any) => {
                    bugsnagClient.leaveBreadcrumb('Requesting increase in FS Quota');
                    w.navigator.PersistentStorage.requestQuota((remaining < minStorage ? minStorage : remaining + minStorage), (size: any) => {
                        _resolve(size);
                    }, (error: any) => {
                        bugsnagClient.notify(error);
                        _reject(error);
                    });
                });
            }
            return prom.then((size: any) => {
                bugsnagClient.leaveBreadcrumb('Request FS Permission');
                w.requestFileSystem(w.PERSISTENT, size, (fs: any) => {
                    resolve(fs);
                }, (e: any) => {
                    bugsnagClient.notify(e);
                    var msg = '';
                    switch (e.code) {
                        case w.FileError.QUOTA_EXCEEDED_ERR:
                            msg = 'QUOTA_EXCEEDED_ERR';
                            break;
                        case w.FileError.NOT_FOUND_ERR:
                            msg = 'NOT_FOUND_ERR';
                            break;
                        case w.FileError.SECURITY_ERR:
                            msg = 'SECURITY_ERR';
                            break;
                        case w.FileError.INVALID_MODIFICATION_ERR:
                            msg = 'INVALID_MODIFICATION_ERR';
                            break;
                        case w.FileError.INVALID_STATE_ERR:
                            msg = 'INVALID_STATE_ERR';
                            break;
                        default:
                            msg = 'Unknown Error';
                            break;
                    }
                    reject(msg);
                });
            })
        }, (error: any) => {
            bugsnagClient.notify(error);
            reject(error);
        });
    })
}

function getFile(path: any) {
    return new Promise((resolve: any, reject: any) => {

        bugsnagClient.leaveBreadcrumb('Requesting File from FS');

        requestAccess().then((fs: any) => {

            fs.root.getFile(path, {
                create: false
            }, (fileEntry: any) => {

                bugsnagClient.leaveBreadcrumb('Reading File from FS');

                fileEntry.file((file: any) => {
                    const reader = new FileReader();
                    reader.onloadend = function() {

                        let body: any;
                        try {
                            body = JSON.parse(this.result as any);
                        } catch(e) {
                            body = this.result;
                        }
                        resolve(body);
                    };
                    reader.onerror = function(error: any) {
                        bugsnagClient.notify(error);
                        reject(error);
                    };
                    reader.readAsText(file);
                }, (error: any) => {
                    bugsnagClient.notify(error);
                    reject(error);
                });

            }, (error: any) => {
                if (error.name !== 'NotFoundError') {
                    bugsnagClient.notify(error);
                }
                reject(error);
            });

        }).catch((error: any) => {
            bugsnagClient.notify(error);
            reject(error);
        });

    });
}

function getAll(prefix: any = undefined, filenames_only: any = false) {
    return new Promise((resolve: any, reject: any) => {
        bugsnagClient.leaveBreadcrumb('Requesting files from FS', {
            prefix
        });

        requestAccess().then((fs: any) => {

            const dirReader = fs.root.createReader();
            let tasks: any = [];

            function readEntries() {
                dirReader.readEntries((results: any) => {
                    if (results.length) {
                        results.forEach((result: any) => {
                            if (!prefix || result.name.match(prefix)) {
                                tasks.push((filenames_only ? Promise.resolve(result.name) : getFile(result.name)));
                            }
                        });
                        readEntries();
                    } else {
                        Promise.all(tasks).then((result: any) => {
                            bugsnagClient.leaveBreadcrumb('Reading files from FS');
                            resolve(result);
                        }).catch((error: any) => {
                            bugsnagClient.notify(error);
                            reject(error);
                        });
                    }
                }, (error: any) => {
                    bugsnagClient.notify(error);
                    reject(error);
                });
            };

            readEntries();

        }).catch((error: any) => {
            bugsnagClient.notify(error);
            reject(error);
        });

    });
}

function putFile(path: any, content: any, createOnly: any, encoding: any = 'application/json') {

    bugsnagClient.leaveBreadcrumb('Creating/updating file on FS', {
        path
    });

    return new Promise((resolve: any, reject: any) => {

        requestAccess().then((fs: any) => {

            fs.root.getFile(path, {
                create: true,
                exclusive: false//createOnly === true
            }, (fileEntry: any) => {

                bugsnagClient.leaveBreadcrumb('Writing File to FS');

                fileEntry.createWriter((fileWriter: any) => {

                    let file_url: any = fileEntry.toURL();

                    fileWriter.onwriteend = () => {
                        if (fileWriter.length === 0) {
                            fileWriter.write(new Blob([(encoding == 'application/json' ? JSON.stringify(content) : content)], {type: encoding}));
                        } else {
                            resolve(content);
                        }
                    };
                    fileWriter.onerror = (e: any) => {
                        bugsnagClient.notify(e);
                        reject(e);
                    };
                    fileWriter.seek(0);
                    fileWriter.truncate(0);

                }, (error: any) => {
                    bugsnagClient.notify(error);
                    reject(error);
                });

            }, (error: any) => {
                if (error.code == 22) {
                    return resolve(content);
                }
                bugsnagClient.notify(error);
                reject(error);
            });

        }).catch((error: any) => {
            bugsnagClient.notify(error);
            reject(error);
        });

    });
}

function removeFile(path: any) {

    bugsnagClient.leaveBreadcrumb('Removing file from FS', {
        path
    });

    return new Promise((resolve: any, reject: any) => {

        requestAccess().then((fs: any) => {

            fs.root.getFile(path, {
                create: false
            }, (fileEntry: any) => {
                fileEntry.remove(() => {
                    resolve();
                });
            }, (error: any) => {
                if (error.name !== 'NotFoundError') {
                    bugsnagClient.notify(error);
                }
                // Silently allow this error to proceed.
                resolve();
            });

        });

    });

}

function flush() {

    bugsnagClient.leaveBreadcrumb('Flush all data from FS');

    return new Promise((resolve: any, reject: any) => {

        requestAccess().then((fs: any) => {

            const dirReader = fs.root.createReader();
            let tasks: any = [];

            function readEntries() {
                dirReader.readEntries((results: any) => {
                    if (results.length) {
                        results.forEach((result: any) => {
                            tasks.push(removeFile(result.name));
                        });
                        readEntries();
                    } else {
                        Promise.all(tasks).then((result: any) => {
                            resolve(result);
                        }).catch((error: any) => {
                            bugsnagClient.notify(error);
                            reject(error);
                        })
                    }
                }, (error: any) => {
                    bugsnagClient.notify(error);
                    reject(error);
                });
            };

            readEntries();

        });

    });

}

function dumpRaw() {

    bugsnagClient.leaveBreadcrumb('Dumping all FS data to server for debugging.');

    return new Promise((resolve: any, reject: any) => {

        requestAccess().then((fs: any) => {

            const dirReader = fs.root.createReader();
            let tasks: any = [];

            function readEntries() {
                dirReader.readEntries((results: any) => {
                    if (results.length) {
                        results.forEach((result: any) => {
                            tasks.push(new Promise((_resolve: any, _reject: any) => {
                                fs.root.getFile(result.name, {
                                    create: false
                                }, (fileEntry: any) => {

                                    fileEntry.file((file: any) => {
                                        const reader = new FileReader();
                                        reader.onloadend = function() {
                                            let content = this.result;
                                            _resolve({ name: result.name, content });
                                        };
                                        reader.onerror = function(error: any) {
                                            bugsnagClient.notify(error);
                                            _reject(error);
                                        };
                                        reader.readAsText(file);
                                    }, (error: any) => {
                                        _reject(error);
                                    });

                                }, (error: any) => {
                                    bugsnagClient.notify(error);
                                    _reject(error);
                                });
                            }))
                        });
                        readEntries();
                    } else {
                        Promise.all(tasks).then((result: any) => {
                            const requestOptions: any = {
                                method: 'POST',
                                headers: Object.assign({}, authHeader(), {
                                    'Content-Type': 'application/json'
                                }),
                                body: JSON.stringify(result)
                            };
                            fetch(`/api/debug`, requestOptions).then(handleResponse).then(() => {
                                resolve();
                            }).catch((error: any) => {
                                bugsnagClient.notify(error);
                                reject(error);
                            });

                        }).catch((error: any) => {
                            bugsnagClient.notify(error);
                            reject(error);
                        });
                    }
                }, (error: any) => {
                    bugsnagClient.notify(error);
                    reject(error);
                });
            };

            readEntries();

        }).catch((error: any) => {
            bugsnagClient.notify(error);
            reject(error);
        });

    });

}

function    downloadRaw() {

    bugsnagClient.leaveBreadcrumb('Saving all FS data to client for debugging.');

    return new Promise((resolve: any, reject: any) => {

        requestAccess().then((fs: any) => {

            const dirReader = fs.root.createReader();
            let tasks: any = [];

            function readEntries() {
                dirReader.readEntries((results: any) => {
                    if (results.length) {
                        results.forEach((result: any) => {
                            tasks.push(new Promise((_resolve: any, _reject: any) => {
                                fs.root.getFile(result.name, {
                                    create: false
                                }, (fileEntry: any) => {

                                    fileEntry.file((file: any) => {
                                        const reader = new FileReader();
                                        reader.onloadend = function() {
                                            let content = this.result;
                                            _resolve({ name: result.name, content });
                                        };
                                        reader.onerror = function(error: any) {
                                            bugsnagClient.notify(error);
                                            _reject(error);
                                        };
                                        reader.readAsText(file);
                                    }, (error: any) => {
                                        _reject(error);
                                    });

                                }, (error: any) => {
                                    bugsnagClient.notify(error);
                                    _reject(error);
                                });
                            }))
                        });
                        readEntries();
                    } else {
                        Promise.all(tasks).then((result: any) => {
                            //JSON.stringify(result)
                            let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(result)),
                                downloadAnchorNode = document.createElement('a');
                            
                            downloadAnchorNode.setAttribute('href',     dataStr);
                            downloadAnchorNode.setAttribute('download', 'raw-data-' + moment().format('YYYY-MM-DD_HH-mm-ss') + '.json');
                            document.body.appendChild(downloadAnchorNode); // required for firefox
                            downloadAnchorNode.click();
                            downloadAnchorNode.remove();

                        }).catch((error: any) => {
                            bugsnagClient.notify(error);
                            reject(error);
                        });
                    }
                }, (error: any) => {
                    bugsnagClient.notify(error);
                    reject(error);
                });
            };

            readEntries();

        }).catch((error: any) => {
            bugsnagClient.notify(error);
            reject(error);
        });

    });

}