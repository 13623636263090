import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions, fileSystemActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    getProfile
};

function login(username: any, password: any) {
    return (dispatch: any) => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                (response: any) => {
                    localStorage.setItem('user_token', response.access_token);
                    dispatch(getProfile());
                    dispatch(success(response));
                    history.push('/');
                },
                (error: any) => {
                    dispatch(failure(typeof error === 'string' ? error : error.message));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request(user: any) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user: any) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error: any) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout(redirect: any) {
    return (dispatch: any) => {
        dispatch(request());

        userService.logout()
            .then(
                (user: any) => {
                    if (redirect) {
                        history.push('/');
                    }
                    dispatch(success());
                },
                (error: any) => {
                    dispatch(failure(typeof error === 'string' ? error : error.message));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );

    };

    function request() { return { type: userConstants.LOGOUT_REQUEST } }
    function success() { return { type: userConstants.LOGOUT_SUCCESS } }
    function failure(error: any) { return { type: userConstants.LOGOUT_FAILURE, error } }
}

function getProfile() {
    return (dispatch: any) => {
        dispatch(request());

        if (localStorage.getItem('user')) {
            return dispatch(success(JSON.parse(localStorage.getItem('user') as any)));
        }

        userService.getProfile()
            .then(
                (user: any) => {
                    localStorage.setItem('user', JSON.stringify(user));
                    dispatch(success(user));
                },
                (error: any) => {
                    dispatch(failure(typeof error === 'string' ? error : error.message));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );

    };

    function request() { return { type: userConstants.GET_USER_REQUEST } }
    function success(user: any) { return { type: userConstants.GET_USER_SUCCESS, user } }
    function failure(error: any) { return { type: userConstants.GET_USER_FAILURE, error } }
}