import { siteConstants } from '../_constants';

export function sites(state = [], action: any) : any {

    switch (action.type) {

        //

        case siteConstants.GETRANDOMS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case siteConstants.GETRANDOMS_SUCCESS:
            return {
                ...state,
                randoms: action.randoms,
                loading: false
            };
        case siteConstants.GETRANDOMS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };

        //

        case siteConstants.GETFAILURELOGS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case siteConstants.GETFAILURELOGS_SUCCESS:
            return {
                ...state,
                logs: action.logs,
                loading: false
            };
        case siteConstants.GETFAILURELOGS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };

        //

        case siteConstants.GETSCHEDULE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case siteConstants.GETSCHEDULE_SUCCESS:
            return {
                ...state,
                schedule: action.schedule,
                loading: false
            };
        case siteConstants.GETSCHEDULE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };

        //

        case siteConstants.SETRANDOMS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case siteConstants.SETRANDOMS_SUCCESS:
            return {
                ...state,
                randoms: action.randoms,
                loading: false
            };
        case siteConstants.SETRANDOMS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };

        //

        default:
            return state
    }
}