export const siteConstants = {
    GETRANDOMS_REQUEST: 'SITES_GETRANDOMS_REQUEST',
    GETRANDOMS_SUCCESS: 'SITES_GETRANDOMS_SUCCESS',
    GETRANDOMS_FAILURE: 'SITES_GETRANDOMS_FAILURE',

    SETRANDOMS_REQUEST: 'SITES_SETRANDOMS_REQUEST',
    SETRANDOMS_SUCCESS: 'SITES_SETRANDOMS_SUCCESS',
    SETRANDOMS_FAILURE: 'SITES_SETRANDOMS_FAILURE',

    FLUSHRANDOMS_REQUEST: 'SITES_FLUSHRANDOMS_REQUEST',
    FLUSHRANDOMS_SUCCESS: 'SITES_FLUSHRANDOMS_SUCCESS',
    FLUSHRANDOMS_FAILURE: 'SITES_FLUSHRANDOMS_FAILURE',

    FLUSHSCHEDULE_REQUEST: 'SITES_FLUSHSCHEDULE_REQUEST',
    FLUSHSCHEDULE_SUCCESS: 'SITES_FLUSHSCHEDULE_SUCCESS',
    FLUSHSCHEDULE_FAILURE: 'SITES_FLUSHSCHEDULE_FAILURE',

    GETFAILURELOGS_REQUEST: 'SITES_GETFAILURELOGS_REQUEST',
    GETFAILURELOGS_SUCCESS: 'SITES_GETFAILURELOGS_SUCCESS',
    GETFAILURELOGS_FAILURE: 'SITES_GETFAILURELOGS_FAILURE',

    GETSCHEDULE_REQUEST: 'SITES_GETSCHEDULE_REQUEST',
    GETSCHEDULE_SUCCESS: 'SITES_GETSCHEDULE_SUCCESS',
    GETSCHEDULE_FAILURE: 'SITES_GETSCHEDULE_FAILURE',

    FLUSHFAILURELOGS_REQUEST: 'SITES_FLUSHFAILURELOGS_REQUEST',
    FLUSHFAILURELOGS_SUCCESS: 'SITES_FLUSHFAILURELOGS_SUCCESS',
    FLUSHFAILURELOGS_FAILURE: 'SITES_FLUSHFAILURELOGS_FAILURE'
};