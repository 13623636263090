// array in local storage for registered users
let users = JSON.parse(localStorage.getItem('users') as string) || [{
    id: 1,
    cms_name: 'Team Valley Web',
    email: 'teamvalleyweb',
    password: 'beach789'
}];

let jobs = JSON.parse(localStorage.getItem('jobs') as string) || [{
    id: 1,
    type: 'microbiological_sampling',
    description: 'Microbiological Sampling Report',
    client: {
        name: 'Team Valley Web',
        address: `3 Octavian Way,\nTeam Valley Trading Estate,\nGateshead, Tyne & Wear`,
        postCode: 'NE11 0HZ',
        contact: 'Andrew Auton',
        tel: '01914976355'
    },
    status: 'new',
    job_details: {
        tasks: [{
            location: 'Location 1',
            location_notes: 'Visit security to get key to the door.',
            status: 'new'
        }, {
            location: 'Location 2',
            status: 'new'
        }, {
            location: 'Location 3',
            status: 'new'
        }]
    }
}, {
    id: 2,
    type: 'l8_temperature_monitoring',
    description: 'L8 Temperature Monitoring Report',
    client: {
        name: 'Team Valley Web',
        address: `3 Octavian Way,\nTeam Valley Trading Estate,\nGateshead, Tyne & Wear`,
        postCode: 'NE11 0HZ',
        contact: 'Andrew Auton',
        tel: '01914976355'
    },
    status: 'new',
    job_details: {
        tasks: [{
            description: 'Description 1',
            tests: ['sentinel', 'temp', 'tmv_outlet', 'flow', 'return'],
            location_notes: 'Visit security to get key to the door.',
            status: 'new'
        }, {
            description: 'Description 2',
            tests: ['flow', 'return'],
            status: 'new'
        }, {
            description: 'Description 3',
            tests: ['sentinel', 'temp'],
            status: 'new'
        }, {
            description: 'Description 3',
            tests: ['sentinel', 'tmv_outlet', 'return'],
            status: 'new'
        }]
    }
}, {
    id: 3,
    type: 'programme_visit',
    description: 'Programme Visit',
    client: {
        name: 'Team Valley Web',
        address: `3 Octavian Way,\nTeam Valley Trading Estate,\nGateshead, Tyne & Wear`,
        postCode: 'NE11 0HZ',
        contact: 'Andrew Auton',
        tel: '01914976355'
    },
    status: 'new',
    job_details: {
        tasks: [{
            location: 'Pool - Indoor',
            type: 'water_sample',
            status: 'new'
        }, {
            location: 'Pool - Outdoor',
            type: 'water_sample',
            status: 'new'
        }, {
            location: 'Spa',
            type: 'water_sample',
            status: 'new'
        }, {
            location: 'Cold Water Storage Tank',
            type: 'water_sample',
            status: 'new'
        }, {
            location: 'Male Showers',
            type: 'water_sample',
            status: 'new'
        }, {
            location: 'Female Showers',
            type: 'water_sample',
            status: 'new'
        }, {
            location: 'Poolside Showers',
            type: 'water_sample',
            status: 'new'
        }, {
            location: 'Family Showers',
            type: 'water_sample',
            status: 'new'
        }, {
            location: 'Disabled Showers',
            type: 'water_sample',
            status: 'new'
        }, {
            location: 'Location 1',
            type: 'temperature',
            status: 'new'
        }, {
            location: 'Location 2',
            type: 'temperature',
            status: 'new'
        }, {
            location: 'Location 3',
            type: 'temperature',
            status: 'new'
        }, {
            location: 'Hydro TR dosed to Cold Water Storage Tank',
            type: 'hydro_tr_dose',
            status: 'new'
        }]
    }
}, {
    id: 4,
    type: 'thermostatic_mixing_valve_service',
    description: 'Thermostatic Mixing Valve Service',
    client: {
        name: 'Team Valley Web',
        address: `3 Octavian Way,\nTeam Valley Trading Estate,\nGateshead, Tyne & Wear`,
        postCode: 'NE11 0HZ',
        contact: 'Andrew Auton',
        tel: '01914976355'
    },
    status: 'new',
    job_details: {
        tasks: [{
            asset_no: '1',
            location: 'Location 1',
            manufacturer_type: 'Manufacturer/Type',
            type: 'maintenance',
            description: 'Strip, clean and descale as required - Valve mechanism',
            status: 'new'
        }, {
            asset_no: '2',
            location: 'Location 2',
            manufacturer_type: 'Manufacturer/Type',
            type: 'temperature',
            description: 'Record cold water temperature pre TMV in accordance with L8 HSG274 part 2',
            status: 'new'
        }, {
            asset_no: '3',
            location: 'Location 3',
            manufacturer_type: 'Manufacturer/Type',
            type: 'fail_safe',
            description: 'Conduct fail safe test on TMV and record Pass/Fail',
            status: 'new'
        }, {
            asset_no: '4',
            location: 'Location 4',
            manufacturer_type: 'Manufacturer/Type',
            type: 'flow_rates',
            description: 'Record Post TMV Flow Rates',
            status: 'new'
        }]
    }
}, {
    id: 5,
    type: 'calorifier_annual_inspection',
    description: 'Calorifier Annual Inspection',
    client: {
        name: 'Team Valley Web',
        address: `3 Octavian Way,\nTeam Valley Trading Estate,\nGateshead, Tyne & Wear`,
        postCode: 'NE11 0HZ',
        contact: 'Andrew Auton',
        tel: '01914976355'
    },
    status: 'new',
    job_details: {
        tasks: [{
            description: 'Inspect insulation on vessel and associated pipework',
            status: 'new'
        }, {
            description: 'Does the system have a recirculating loop?',
            status: 'new'
        }, {
            description: 'Does the system have a recirculating loop?',
            status: 'new'
        }]
    }
}];

export function configureFakeBackend() {
    let realFetch = window.fetch;
    window.fetch = function (url: string, opts: any) {
        console.log(`Fetching ${url}`);
        return new Promise((resolve: any, reject: any) => {
            // wrap in timeout to simulate server api call
            setTimeout(() => {

                // authenticate
                if (!('API_DOMAIN' in window) && url.endsWith('/oauth/token') && opts.method === 'POST') {
                    // get parameters from post request
                    let params = JSON.parse(opts.body);

                    // find if any user matches login credentials
                    let filteredUsers = users.filter((user: any) => {
                        return user.username === params.username && user.password === params.password;
                    });

                    if (filteredUsers.length) {
                        // if login details are valid return user details and fake jwt token
                        let responseJson = {
                            access_token: 'fake-jwt-token',
                            token_type: 'Bearer'
                        };
                        resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(responseJson)) });
                    } else {
                        // else return error
                        reject('Username or password is incorrect');
                    }

                    return;
                }

                // authenticate
                if (!('API_DOMAIN' in window) && url.endsWith('/api/user')) {

                    if (opts.headers && opts.headers.Authorization) {
                        resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(users[0])) });
                    } else {
                        // else return error
                        reject('Unauthorised');
                    }

                    return;
                }

                // get jobs
                if (!('API_DOMAIN' in window) && url.endsWith('/jobs') && opts.method === 'GET') {
                    // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
                    if (opts.headers && opts.headers.Authorization) {
                        resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(jobs))});
                    } else {
                        // return 401 not authorised if token is null or invalid
                        reject('Unauthorised');
                    }

                    return;
                }

                // get job by id
                if (!('API_DOMAIN' in window) && url.match(/\/jobs\/\d+$/) && opts.method === 'GET') {
                    // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
                    if (opts.headers && opts.headers.Authorization) {
                        // find job by id in jobs array
                        let urlParts = url.split('/');
                        let id = parseInt(urlParts[urlParts.length - 1]);
                        let matchedJobs = jobs.filter((job: any) => { return job.id === id; });
                        let job = matchedJobs.length ? matchedJobs[0] : null;

                        if (job) {
                            // respond 200 OK with job
                            return resolve({ok: true, text: () => Promise.resolve(JSON.stringify(job))});
                        }
                        reject('Requested Job could not be found');

                    } else {
                        // return 401 not authorised if token is null or invalid
                        reject('Unauthorised');
                    }

                    return;
                }

                // update job by id
                if (!('API_DOMAIN' in window) && url.match(/\/jobs\/\d+$/) && opts.method === 'PUT') {
                    // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
                    if (opts.headers && opts.headers.Authorization) {
                        // find job by id in jobs array
                        let urlParts = url.split('/');
                        let id = parseInt(urlParts[urlParts.length - 1]);
                        jobs.forEach((job: any, idx: any) => {
                            if (job.id === id) {
                                jobs[idx] = JSON.parse(opts.body);
                            }
                        });
                        localStorage.setItem('jobs', JSON.stringify(jobs));

                        // respond 200 OK with job
                        resolve({ ok: true, text: () => Promise.resolve(JSON.stringify({
                            status: 'OK',
                            job_id: id
                        }))});
                    } else {
                        // return 401 not authorised if token is null or invalid
                        reject('Unauthorised');
                    }

                    return;
                }

                // get user by id
                if (url.match(/\/users\/\d+$/) && opts.method === 'GET') {
                    // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
                    if (opts.headers && opts.headers.Authorization) {
                        // find user by id in users array
                        let urlParts = url.split('/');
                        let id = parseInt(urlParts[urlParts.length - 1]);
                        let matchedUsers = users.filter((user: any) => { return user.id === id; });
                        let user = matchedUsers.length ? matchedUsers[0] : null;

                        // respond 200 OK with user
                        resolve({ ok: true, text: () => JSON.stringify(user)});
                    } else {
                        // return 401 not authorised if token is null or invalid
                        reject('Unauthorised');
                    }

                    return;
                }

                // register user
                if (url.endsWith('/users/register') && opts.method === 'POST') {
                    // get new user object from post body
                    let newUser = JSON.parse(opts.body);

                    // validation
                    let duplicateUser = users.filter((user: any) => { return user.username === newUser.username; }).length;
                    if (duplicateUser) {
                        reject('Username "' + newUser.username + '" is already taken');
                        return;
                    }

                    // save new user
                    newUser.id = users.length ? Math.max(...users.map((user: any) => user.id)) + 1 : 1;
                    users.push(newUser);
                    localStorage.setItem('users', JSON.stringify(users));

                    // respond 200 OK
                    resolve({ ok: true, text: () => Promise.resolve() });

                    return;
                }

                // delete user
                if (url.match(/\/users\/\d+$/) && opts.method === 'DELETE') {
                    // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
                    if (opts.headers && opts.headers.Authorization) {
                        // find user by id in users array
                        let urlParts = url.split('/');
                        let id = parseInt(urlParts[urlParts.length - 1]);
                        for (let i = 0; i < users.length; i++) {
                            let user = users[i];
                            if (user.id === id) {
                                // delete user
                                users.splice(i, 1);
                                localStorage.setItem('users', JSON.stringify(users));
                                break;
                            }
                        }

                        // respond 200 OK
                        resolve({ ok: true, text: () => Promise.resolve() });
                    } else {
                        // return 401 not authorised if token is null or invalid
                        reject('Unauthorised');
                    }

                    return;
                }

                // pass through any requests not handled above
                realFetch(url, opts).then(response => resolve(response)).catch(err => reject(err));

            }, 500);
        });
    }
}