import {siteConstants} from '../_constants';
import {siteService} from '../_services';
import { alertActions } from './';

export const siteActions = {
    getRandoms,
    getBulkRandoms,
    updateRandoms,
    flushRandoms,
    getFailureLogs,
    getBulkFailureLogs,
    flushFailureLogs,
    getWorkSchedule,
    flushWorkSchedule,
    getBulkWorkSchedule
};

function getRandoms(site_id: any, force: any = false) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.getRandoms(site_id, force)
            .then(
                (randoms: any) => dispatch(success(randoms)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.GETRANDOMS_REQUEST } }
    function success(randoms: any) { return { type: siteConstants.GETRANDOMS_SUCCESS, randoms } }
    function failure(error: any) { return { type: siteConstants.GETRANDOMS_FAILURE, error } }
}

function getFailureLogs(site_id: any, force: any = false) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.getFailureLogs(site_id, force)
            .then(
                (logs: any) => dispatch(success(logs)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.GETFAILURELOGS_REQUEST } }
    function success(logs: any) { return { type: siteConstants.GETFAILURELOGS_SUCCESS, logs } }
    function failure(error: any) { return { type: siteConstants.GETFAILURELOGS_FAILURE, error } }
}

function getWorkSchedule(site_id: any, force: any = false) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.getWorkSchedule(site_id, force)
            .then(
                (logs: any) => dispatch(success(logs)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.GETSCHEDULE_REQUEST } }
    function success(schedule: any) { return { type: siteConstants.GETSCHEDULE_SUCCESS, schedule } }
    function failure(error: any) { return { type: siteConstants.GETSCHEDULE_FAILURE, error } }
}

function getBulkRandoms(site_id: any, force: any = false) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.getBulkRandoms(site_id, force)
            .then(
                (randoms: any) => dispatch(success(randoms)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.GETRANDOMS_REQUEST } }
    function success(randoms: any) { return { type: siteConstants.GETRANDOMS_SUCCESS, randoms } }
    function failure(error: any) { return { type: siteConstants.GETRANDOMS_FAILURE, error } }
}

function getBulkWorkSchedule(site_id: any, force: any = false) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.getBulkWorkSchedule(site_id, force)
            .then(
                (schedule: any) => dispatch(success(schedule)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.GETSCHEDULE_REQUEST } }
    function success(schedule: any) { return { type: siteConstants.GETSCHEDULE_SUCCESS, schedule } }
    function failure(error: any) { return { type: siteConstants.GETSCHEDULE_FAILURE, error } }
}

function getBulkFailureLogs(site_id: any, force: any = false) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.getBulkFailureLogs(site_id, force)
            .then(
                (logs: any) => dispatch(success(logs)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.GETFAILURELOGS_REQUEST } }
    function success(logs: any) { return { type: siteConstants.GETFAILURELOGS_SUCCESS, logs } }
    function failure(error: any) { return { type: siteConstants.GETFAILURELOGS_FAILURE, error } }
}

function updateRandoms(site_id: any, randoms: any) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.updateRandoms(site_id, randoms)
            .then(
                () => dispatch(success(randoms)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.SETRANDOMS_REQUEST } }
    function success(randoms: any) { return { type: siteConstants.SETRANDOMS_SUCCESS, randoms } }
    function failure(error: any) { return { type: siteConstants.SETRANDOMS_FAILURE, error } }
}

function flushRandoms(site_id: any) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.flushRandoms(site_id)
            .then(
                () => dispatch(success()),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.FLUSHRANDOMS_REQUEST } }
    function success() { return { type: siteConstants.FLUSHRANDOMS_SUCCESS } }
    function failure(error: any) { return { type: siteConstants.FLUSHRANDOMS_FAILURE, error } }
}

function flushWorkSchedule(site_id: any) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.flushWorkSchedule(site_id)
            .then(
                () => dispatch(success()),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.FLUSHSCHEDULE_REQUEST } }
    function success() { return { type: siteConstants.FLUSHSCHEDULE_SUCCESS } }
    function failure(error: any) { return { type: siteConstants.FLUSHSCHEDULE_FAILURE, error } }
}

function flushFailureLogs(site_id: any) {
    return (dispatch: any) => {
        dispatch(request());

        siteService.flushFailureLogs(site_id)
            .then(
                () => dispatch(success()),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: siteConstants.FLUSHFAILURELOGS_REQUEST } }
    function success() { return { type: siteConstants.FLUSHFAILURELOGS_SUCCESS } }
    function failure(error: any) { return { type: siteConstants.FLUSHFAILURELOGS_FAILURE, error } }
}