export function translateUnit(unit: any) {
    switch (unit) {
        case 'd':
            return '&deg;C';
        case 'm':
            return 'mg';
        case 'g':
            return 'g';
        case 'p':
            return 'ppm';
        case 'r':
            return 'mg/l';
        case 'l':
            return 'g/l';
        case 't':
            return '%';
        case 'c':
            return 'Cfu/ml';
        case 'f':
            return 'Cfu/100 ml';
        case 'u':
            return 'Cfu/l';
        case 'z':
            return 'ug/l';
        default:
            return unit;
    }
}