export function formDataToObject(fd: any) {
    let fields: any = {},
        parts: any, tmp: any;
    fd.forEach((value: any, key: any) => {
        tmp = fields;
        parts = key.match(/\w+/g);
        parts.forEach((part: any, idx: any) => {
            if (!(part in tmp)) {
                tmp[part] = {};
            }
            if (idx === parts.length - 1) {
                tmp[part] = value;
            } else {
                tmp = tmp[part];
            }
        });
    });
    return fields;
}