import React from 'react';
import './index.scss';

export class Loader extends React.Component<any, any> {

    render() {
        return (
            <div className="Loader">
                <div className="LoaderContentWrapper">
                    <div className="Circle">
                        <div className="Wave" />
                    </div>
                    <p className="lead"><strong>Loading...</strong></p>
                </div>
            </div>
        );
    }
}