import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
var moment = require('moment');

const metadata: any = require('./metadata.json');

const env: any = process.env.REACT_APP_ENV;
const nice_env: any = env.toUpperCase().substring(0, 1) + env.toLowerCase().substring(1);

if (!(env in metadata)) {
    metadata[env] = {
        build: 1,
        build_date: moment().format('DD/MM/YYYY HH:mm')
    }
}

import { store, bugsnagClient } from './_helpers';
import { App } from './Components/App';

import * as serviceWorker from './serviceWorker';

import jquery from 'jquery';

(window as any).jQuery = jquery;
(window as any).$ = jquery;

require('bootstrap/js/dist/collapse');

import { configureFetch } from './_helpers';

// Configure API.
configureFetch();

const ErrorBoundary = bugsnagClient.getPlugin('react');


if (window.location.href.indexOf('.uk') == -1) {
    if (confirm('The app has recently moved to https://app.safe-water.uk.\n\nIf you have pending changes, please click cancel and continue working as normal.\n\n==========================================\nONLY CLICK OK IF YOU HAVE FULLY SYNCHRONISED!!!\n==========================================')) {
        window.location.href = 'https://app.safe-water.uk';
    }
}

import './index.scss';

render(
    <ErrorBoundary>
        <Provider store={store}>
            <App environment={nice_env} version={metadata[env].build} build_date={metadata[env].build_date} />
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: () => {
        if (confirm('An update is available, would you like to load it now?\n\nIf you select cancel, it will load the next time you visit the app.')) {
            window.location.reload();
        }
    }
});
