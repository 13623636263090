import React from 'react';
import { connect } from 'react-redux';
import { InternalLayout } from '../../_layouts';
import {alertActions, jobActions} from '../../_actions';
import { history } from '../../_helpers';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';

import './index.scss';
import {jobService} from "../../_services";

class Calendar extends React.Component<any, any> {

    private calendarRef: any;

    constructor(props: any) {
        super(props);

        this.calendarRef = React.createRef();

        this.eventRender = this.eventRender.bind(this);
        this.eventClick = this.eventClick.bind(this);
    }

    eventClick(e: any) {

        const { dispatch } = this.props;

        jobService.getOne(e.event.id).then(() => {
            history.push(`/job/${e.event.id}`);
        }).catch((e) => {
            dispatch(alertActions.error(e));
        });
    }

    eventRender(e: any) {
        e.el.setAttribute('title', e.event.title);
    }

    componentDidMount() {
        const { dispatch, calendarSyncing, calendarSyncFailed, fsReady } = this.props;
        if (fsReady && !calendarSyncing && !calendarSyncFailed) {
            dispatch(jobActions.sync());
            dispatch(jobActions.getCalendar());
        }
    }

    componentDidUpdate(prevProps: any) {
        const { dispatch, calendarSyncing, calendar_items, calendarSyncFailed, fsReady } = this.props;
        if (fsReady && !calendarSyncing && calendar_items === undefined && !calendarSyncFailed) {
            dispatch(jobActions.sync());
            dispatch(jobActions.getCalendar());
        }
    }

    render() {

        const { calendar_items, calendarSyncing, calendarSyncFailed} = this.props;

        let events: any = {};
        if (!calendarSyncing && calendar_items !== undefined && !calendarSyncFailed) {

            calendar_items.forEach((job: any) => {
                if (!(job.route_id in events)) {
                    events[job.route_id] = {
                        events: [],
                        name: job.route,
                        backgroundColor: job.route_colour,
                        textColor: '#FFFFFF'
                    };
                }
                events[job.route_id].events.push({
                    title: job.title,
                    id: job.id,
                    start: moment(job.week_commencing, 'DD/MM/YYYY').format('YYYY-MM-DD')
                });
            });

            setTimeout(() => {
                this.calendarRef.current.calendar.updateSize()
            }, 500);

            events = Object.values(events);

        }

        return (
            <InternalLayout>
                <div className="container">
                    <FullCalendar ref={this.calendarRef} eventLimit={5} eventRender={this.eventRender} eventClick={this.eventClick} eventSources={events} height="auto" defaultView="dayGridMonth" plugins={[ dayGridPlugin ]} />
                </div>
            </InternalLayout>
        );
    }
}

function mapStateToProps(state: any) {
    const { calendar, fileSystem } = state;
    return {
        calendarSyncing: calendar.calendar_loading,
        calendarSyncFailed: !!calendar.calendar_error,
        calendar_items: calendar.calendar_items,
        fsReady: fileSystem.ready
    };
}

const connectedCalendar = connect(mapStateToProps)(Calendar);
export { connectedCalendar as Calendar };