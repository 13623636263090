import React from 'react';
import { connect } from 'react-redux';
import { InternalLayout } from '../../_layouts';
import { Link } from 'react-router-dom';
import { Box } from '../../_components';
import { jobActions, userActions } from '../../_actions';
import { jobConstants, mapJobStatus } from '../../_constants';
import moment from 'moment';

import './index.scss';

class Dashboard extends React.Component<any, any> {

    componentDidMount() {
        const { dispatch, jobSyncing, jobSyncFailed, fsReady } = this.props;
        if (fsReady && !jobSyncing && !jobSyncFailed) {
            dispatch(jobActions.sync());
            dispatch(userActions.getProfile());
        }
    }

    componentDidUpdate(prevProps: any) {
        const { dispatch, jobs, jobSyncing, jobSyncFailed, fsReady } = this.props;
        if (fsReady && !jobSyncing && jobs === undefined && !jobSyncFailed) {
            dispatch(jobActions.sync());
            dispatch(userActions.getProfile());
        }
    }

    render() {
        const { jobs, jobSyncing } = this.props;

        const weekCommencing = moment().startOf('isoWeek').format('DD/MM/YYYY');
        const currentJobs = (jobs ? jobs.filter((job: any) => {
            return job && moment(job.week_commencing, 'DD/MM/YYYY').startOf('isoWeek').format('DD/MM/YYYY') === weekCommencing;
        }).sort((a: any, b: any) => {
            return moment(a.week_commencing, 'DD/MM/YYYY').diff(moment(b.week_commencing, 'DD/MM/YYYY'));
        }) : false);

        let otherJobs: any = {};
        if (jobs && jobs.length) {
            jobs.forEach((job: any) => {
                let wc = moment(job.week_commencing, 'DD/MM/YYYY').startOf('isoWeek'),
                    d = wc.format('DD/MM/YYYY');
                if (job && d !== weekCommencing) {
                    let key = `${job.route} - WC ${d}`;
                    if (!(key in otherJobs)) {
                        otherJobs[key] = {
                            route_id: job.route_id,
                            title: key,
                            week_commencing: d,
                            sort: wc.unix(),
                            jobs: []
                        };
                    }
                    otherJobs[key].jobs.push(job);
                }
            });

            otherJobs = Object.values(otherJobs).sort((a: any, b: any) => {
                return a.sort - b.sort;
            });
        }

        return (
            <InternalLayout>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Box title="Current Jobs" theme="blue" icon="fa-briefcase">
                                {jobSyncing ? (
                                    <div className="Circle">
                                        <div className="Wave" />
                                    </div>
                                ) : (currentJobs.length ? (
                                    <ul className="BoxList Scrollable">
                                    {currentJobs.map((job: any) => {
                                        const { icon, name } = mapJobStatus(job.status);
                                        let completed_tasks = 0;
                                        Object.keys(job.tasks).forEach((task_id: any) => {
                                            let task = job.tasks[task_id];
                                            if ([jobConstants.TASK_STATUS_COMPLETE, jobConstants.TASK_STATUS_QUEUED, jobConstants.TASK_STATUS_SKIPPED].indexOf(task.status) > -1) {
                                                completed_tasks++;
                                            }
                                        });
                                        return (
                                            <li key={`job_${job.id}`} className={`job-status-${job.status}`}>
                                                <Link to={`job/${job.id}`}>
                                                    <span>
                                                        <i className={`fa ${icon}`} title={name} data-toggle="tooltip" />
                                                    </span>
                                                    <strong>
                                                        {job.site.company} - {job.site.name}
                                                        <small>({completed_tasks}/{job.tasks.length} task{job.tasks.length == 1 ? '' : 's'}) - {job.week_commencing}{job.job_reference ? ' - ' + job.job_reference : ''}</small>
                                                    </strong>
                                                    <i className="fa fa-caret-right" />
                                                </Link>
                                            </li>
                                        );
                                    })}
                                    </ul>
                                ) : (<p className="text-center">You do not currently have any jobs for this week.</p>))}
                            </Box>
                        </div>
                        <div className="col-md-6">
                            <Box title="Routes" theme="dark-blue" icon="fa-map-marker">
                                {jobSyncing ? (
                                    <div className="Circle">
                                        <div className="Wave" />
                                    </div>
                                ) : (otherJobs.length ? (
                                    <ul className="BoxList Scrollable">
                                        {otherJobs.map((group: any, idx: any) => {
                                            let completed_jobs = 0;
                                            group.jobs.forEach((job: any) => {
                                                if ([jobConstants.JOB_STATUS_COMPLETE, jobConstants.JOB_STATUS_QUEUED, jobConstants.JOB_STATUS_FAILED].indexOf(job.status) > -1) {
                                                    completed_jobs++;
                                                }
                                            });
                                            return (
                                                <li key={idx}>
                                                    <Link to={`route/${group.route_id}/${group.week_commencing.replace(/\//g, '-')}`}>
                                                        <strong>
                                                            {group.title}
                                                            <small>({completed_jobs}/{group.jobs.length} job{group.jobs.length == 1 ? '' : 's'})</small>
                                                        </strong>
                                                        <i className="fa fa-caret-right" />
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : (<p className="text-center">You do not have any other jobs.</p>))}
                            </Box>
                        </div>
                    </div>
                </div>
            </InternalLayout>
        );
    }
}

function mapStateToProps(state: any) {
    const { authentication, fileSystem, jobs } = state;
    return {
        user: authentication.user,
        fsReady: fileSystem.ready,
        jobSyncing: jobs.syncing,
        jobSyncFailed: !!jobs.error,
        jobs: jobs.items
    };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };