import { authHeader } from '../_helpers';
import { handleResponse, fileSystemService } from '../_services';

export const userService = {
    login,
    logout,
    getProfile
};

function login(username: any, password: any) {
    const requestOptions: any = {
        method: 'POST',
        body: JSON.stringify({
            username,
            password,
            grant_type: 'password',
            client_id: 1,
            client_secret: 'd86b575d5f6aa51f47750b137fd87593',
            scope: 'engineer'
        })
    };

    return fetch(`/oauth/token`, requestOptions)
        .then(handleResponse);
}

export function logout() {
    return new Promise((resolve: any, reject: any) => {
        localStorage.removeItem('user_token');
        localStorage.removeItem('user');
        resolve();
        // fileSystemService.flush().then(() => {
        //     localStorage.removeItem('user_token');
        //     localStorage.removeItem('user');
        //     resolve();
        // }).catch(reject);
    });
}

function getProfile() {
    const requestOptions: any = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch('/api/user', requestOptions)
        .then(handleResponse);
}