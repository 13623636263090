import { jobConstants } from '../_constants';
import { jobService } from '../_services';
import { alertActions, siteActions, testResultActions } from './';
import { history } from '../_helpers';

export const jobActions = {
    save,
    getOne,
    getAll,
    sync,
    getCalendar
};

function save(job: any, message: any = '', redirect: any = true, redirectWhere: any = false) {
    return (dispatch: any) => {
        dispatch(request(job));

        const { header, ...rest } = job;

        jobService.save(rest)
            .then(
                (job: any) => {
                    dispatch(success(job));
                    if (redirect) {
                        if (redirectWhere) {
                            history.push(redirectWhere);
                        } else {
                            if ([jobConstants.JOB_STATUS_QUEUED, jobConstants.JOB_STATUS_COMPLETE, jobConstants.JOB_STATUS_FAILED].indexOf(job.status) === -1) {
                                history.push(`/job/${job.id}`);
                            } else {
                                history.push(`/`);
                            }
                        }
                    }
                    if (message) {
                        dispatch(alertActions.success(message));
                    }
                },
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request(job: any) { return { type: jobConstants.SAVE_REQUEST, job } }
    function success(job: any) { return { type: jobConstants.SAVE_SUCCESS, job } }
    function failure(error: any) { return { type: jobConstants.SAVE_FAILURE, error } }
}

function getOne(job_id: any) {
    return (dispatch: any) => {
        dispatch(request());

        jobService.getOne(job_id)
            .then(
                (job: any) => {
                    dispatch(success(job));
                    dispatch(siteActions.getRandoms(job.site.id));
                    dispatch(siteActions.getWorkSchedule(job.site.id));
                    dispatch(siteActions.getFailureLogs(job.site.id));
                    dispatch(testResultActions.getFailures(job.site.id));
                },
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: jobConstants.GETONE_REQUEST } }
    function success(job: any) { return { type: jobConstants.GETONE_SUCCESS, job } }
    function failure(error: any) { return { type: jobConstants.GETONE_FAILURE, error } }
}

function getAll() {
    return (dispatch: any) => {
        dispatch(request());

        jobService.getAll()
            .then(
                (jobs: any) => dispatch(success(jobs)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: jobConstants.GETALL_REQUEST } }
    function success(jobs: any) { return { type: jobConstants.GETALL_SUCCESS, jobs } }
    function failure(error: any) { return { type: jobConstants.GETALL_FAILURE, error } }
}

function getCalendar(force: any = false) {
    return (dispatch: any) => {
        dispatch(request());

        jobService.getCalendar(force)
            .then(
                (jobs: any) => dispatch(success(jobs)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: jobConstants.GETCALENDAR_REQUEST } }
    function success(calendar_items: any) { return { type: jobConstants.GETCALENDAR_SUCCESS, calendar_items } }
    function failure(error: any) { return { type: jobConstants.GETCALENDAR_FAILURE, error } }
}

function sync(force: any = false) {
    return (dispatch: any) => {
        dispatch(request());

        jobService.sync(force)
            .then(
                (jobs: any) => {

                    jobs = jobs.filter((job: any) => job);

                    let site_ids: any = [],
                        unit_ids: any = [];

                    jobs.forEach((job: any) => {
                        if(job.site && site_ids.indexOf(job.site.id) === -1) {
                            site_ids.push(job.site.id);
                        }
                        job.tasks.forEach((task: any) => {
                            if(task.unit && unit_ids.indexOf(task.unit.id) === -1) {
                                unit_ids.push(task.unit.id);
                            }
                        });
                    });

                    dispatch(siteActions.getBulkRandoms(site_ids, force));
                    dispatch(siteActions.getBulkFailureLogs(site_ids, force));
                    dispatch(siteActions.getBulkWorkSchedule(site_ids, force));
                    dispatch(testResultActions.getBulkFailures(site_ids, unit_ids, force));
                    dispatch(jobActions.getCalendar(force));

                    dispatch(success(jobs.sort((a: any, b: any) => {
                        return a.id - b.id;
                    })))
                },
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: jobConstants.SYNC_REQUEST } }
    function success(jobs: any) { return { type: jobConstants.SYNC_SUCCESS, jobs } }
    function failure(error: any) { return { type: jobConstants.SYNC_FAILURE, error } }
}