import React from 'react';
import Logo from '../../assets/images/Logo.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions, alertActions, jobActions, fileSystemActions } from '../../_actions';

import './index.scss';

class InternalLayout extends React.Component<any, any> {

    resyncTimer: any;

    logout() {
        if (confirm('You do not need to log out unless you are changing engineers.\n\nAre you sure you want to log out?')) {
            this.props.dispatch(userActions.logout(true));
        }
    }

    resync(force: any = false) {
        return () => {
            this.props.dispatch(jobActions.sync(force));
            this.props.dispatch(alertActions.clear());
        };
    }

    flush() {
        if (confirm('This will clear any unsent jobs. If you are unsure, click cancel!\n\nAre you sure you want to flush the local storage?')) {
            this.props.dispatch(fileSystemActions.flush());
            this.props.dispatch(alertActions.clear());
        }
    }

    componentWillUpdate(newProps: any) {
        if (this.props.online === false && newProps.online === true) {
            this.resync();
        }
        if (this.props.user && this.props.flushing && newProps.flushed) {
            this.resync();
        }
    }

    autoResync() {
        let me = this;
        this.resyncTimer = setTimeout(() => {
            me.props.dispatch(jobActions.sync());
        },  5 * 60 * 1000);
    }

    componentDidMount() {
        this.autoResync();
    }

    componentWillUnmount() {
        clearTimeout(this.resyncTimer);
    }

    render() {
        const { user, alert, jobsSyncing, lastSyncDate, flushing } = this.props;
        this.logout = this.logout.bind(this);
        this.resync = this.resync.bind(this);
        this.flush = this.flush.bind(this);
        this.autoResync = this.autoResync.bind(this);
        return (
            <div className="InternalLayout">
                <header id="header">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                                <Link to={'/'}><img src={ Logo } alt="SafeWater" className="img-fluid logo" /></Link>
                                <p id="header-salutation">Hi, {user && user.cms_name}</p>
                                <p id="header-sync-status">Last Sync: {lastSyncDate} <button className="ml-2 btn btn-sm btn-outline-success" disabled={jobsSyncing || flushing} onClick={this.resync(true)}><i className={`fa fa-refresh ${jobsSyncing ? 'fa-spin' : ''}`} /></button> <button className="d-none ml-2 btn btn-sm btn-outline-danger" disabled={jobsSyncing || flushing} onClick={this.flush}><i className={`fa fa-trash ${flushing ? 'fa-spin' : ''}`} /></button></p>
                            </div>
                            <div className="col-md-7">
                                <nav id="header-nav">
                                    <ul>
                                        <li><Link to={'/'}>
                                            <i className="fa fa-dashboard" />
                                            <span>Dashboard</span>
                                        </Link></li>
                                        <li><Link to={'/calendar'}>
                                            <i className="fa fa-calendar" />
                                            <span>Calendar</span>
                                        </Link></li>
                                        <li><a onClick={this.logout}>
                                            <i className="fa fa-sign-out" />
                                            <span>Logout</span>
                                        </a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
                <section id="content">
                    {alert.message && (
                        <div className="container">
                            <div className={`alert ${alert.type} alert-dismissible fade show`} role="alert">
                                {alert.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    )}
                    {this.props.children}
                </section>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    const { alert, authentication, jobs, fileSystem } = state;
    return {
        alert,
        online: alert.online,
        user: authentication.user,
        jobsSyncing: jobs.syncing,
        lastSyncDate: jobs.lastSyncDate,
        flushing: fileSystem.flushing,
        flushed: fileSystem.flushed
    };
}

const connectedInternalLayout = connect(mapStateToProps)(InternalLayout);
export { connectedInternalLayout as InternalLayout };