import React from 'react';
import { connect } from 'react-redux';
import { Box } from '../../../../_components/Box';
import { mapTaskStatus } from '../../../../_constants';
import { Switch, Route, Link } from 'react-router-dom';
import { JobLayout } from '../../../../_layouts';
import { jobConstants } from '../../../../_constants';
import { jobActions } from '../../../../_actions';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';

import './index.scss';

class ProgrammeVisitForm extends React.Component<any, any> {

    private signatureBox: any;
    private taskForm: any;

    private supplyTypes: any = ['Flow', 'Return', 'Hot', 'Cold'];

    private resizeSignatureCanvas() {
        if (this.signatureBox.current && (!('width' in this.state.canvasProps) || this.state.canvasProps.width != this.signatureBox.current._canvas.parentElement.clientWidth)) {
            this.state.canvasProps.width = this.signatureBox.current._canvas.parentElement.clientWidth;
            this.setState(this.state);
        }
    }

    constructor(props: any) {
        super(props);

        this.state = {
            errors: {},
            canvasProps: {
                height: '200px'
            }
        };

        // bind all handlers to the component to become state aware.
        this.completeTask = this.completeTask.bind(this);
        this.completeJob = this.completeJob.bind(this);
        this.skipTask = this.skipTask.bind(this);

        // create references for elements to bind to.
        this.signatureBox = React.createRef();
        this.taskForm = React.createRef();

        // listen for any resize events to resize the signature canvas.
        window.addEventListener('resize', this.resizeSignatureCanvas.bind(this), false);
    }

    componentDidUpdate() {
        this.resizeSignatureCanvas();
    }

    componentWillUnmount() {
        // kill the resize event handler.
        window.removeEventListener('resize', this.resizeSignatureCanvas);
    }

    componentDidMount() {
        this.resizeSignatureCanvas();
    }

    /**
     * Validate and save a task.
     * @param task_id: associated task.
     * @returns function
     */
    completeTask(task_id: any) {
        return (e: any) => {
            e.preventDefault();

            // Get all the form data.
            const fd = new FormData(e.target);

            let errors: any = {},
                me = this;

            // Ensure all fields that are required, have a value.
            fd.forEach((value: any, key: any) => {
                if (value.length <= 0 && ['comments'].indexOf(key) === -1) {
                    errors[key] = 'This field is required';
                }
            });

            // Update the error state.
            this.setState({
                errors
            });

            // If there weren't any errors, proceed with completing the task.
            if (Object.keys(errors).length === 0) {
                // Update the task.
                let task = me.props.job.tasks[task_id];
                fd.forEach((value: any, key: any) => {
                    task[key] = value;
                });
                // Set the task status.
                task.status = jobConstants.TASK_STATUS_COMPLETE;
                task.completed_at = moment.now();
                // Update the job with the updated task.
                me.props.job.tasks[task_id] = task;
                // Mark the job as open, now we've started working on it.
                me.props.job.status = jobConstants.JOB_STATUS_OPEN;
                // Now dispatch the job save event to store the changes.
                me.props.dispatch(jobActions.save(me.props.job, 'Task completed successfully'));
            }
        }
    }

    /**
     * Validate and mark a job as completed.
     * @returns function
     */
    completeJob() {
        return (e: any) => {
            e.preventDefault();

            // Get all the form data.
            const fd = new FormData(e.target);

            let errors: any = {},
                me = this,
                name: any = fd.get('name');

            // Ensure the client has entered their name.
            if (name === null || name.length <= 0) {
                errors['name'] = 'Please type in your name';
            }

            // Ensure the client has signed.
            if (this.signatureBox.current.isEmpty()) {
                errors['signature'] = 'Please provide a signature';
            }

            // Update the error state.
            this.setState({
                errors
            });

            // If there weren't any errors, proceed with completing the job.
            if (Object.keys(errors).length === 0) {
                // Mark the job as queued, to be sent when possible.
                me.props.job.status = jobConstants.JOB_STATUS_QUEUED;
                // Set the clients name and signature against the job.
                me.props.job.signed_by = name;
                me.props.job.signature = this.signatureBox.current.toDataURL();
                me.props.job.completed_at = moment.now();
                // Now dispatch the job save event to store the changes.
                me.props.dispatch(jobActions.save(me.props.job, 'Job successfully queued'));
            }
        };
    }

    /**
     * Validate and mark a task as skipped.
     * @returns function
     */
    skipTask(task_id: any) {
        return (e: any) => {
            e.preventDefault();

            // Get all the form data.
            const fd = new FormData(this.taskForm.current);

            let errors: any = {},
                me = this,
                comments: any = fd.get('comments');

            // Ensure a comment has been specified.
            if (comments === null || comments.length <= 0) {
                errors['comments'] = 'Please state why this task was skipped.';
            }

            // Update the error state.
            this.setState({
                errors
            });

            // If there weren't any errors, proceed with skipping the task.
            if (Object.keys(errors).length === 0) {
                // Get the associated task.
                let task = me.props.job.tasks[task_id];
                // Mark the task as skipped.
                task.status = jobConstants.TASK_STATUS_SKIPPED;
                task.completed_at = moment.now();
                // Set the task comments.
                task.comments = comments;
                // Update the job with the updated task.
                me.props.job.tasks[task_id] = task;
                // Now dispatch the job save event to store the changes.
                me.props.dispatch(jobActions.save(me.props.job, 'Task skipped'));
            }
        }
    }

    render() {
        const { job } = this.props;
        return (
            <JobLayout header={() => {
                return <h1>Microbiological Sample Report</h1>;
            }}>
                <div className="Form">
                    <div className="container">
                        <Switch>
                            <Route path={`/job/:job_id/task/:task_id/water_sample`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Water sample
                                return (
                                    <>
                                        <h3>Water Sample: {job.tasks[props.match.params.task_id].location}</h3>
                                        <form ref={this.taskForm} onSubmit={this.completeTask(props.match.params.task_id)}>
                                            {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                            )}
                                            {job.tasks[props.match.params.task_id].location_notes && (
                                                <div className="alert alert-info">
                                                    <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                                </div>
                                            )}
                                            {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                                <div className="alert alert-warning">
                                                    <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                                </div>
                                            )}
                                            <div className="form-group required">
                                                <label className="text-primary"><strong>Bacteria</strong></label>
                                                <div className="FormRadioCheckoutGroup">
                                                    <input type="radio" className="d-none" defaultChecked={true} name="bacteria" value="" />
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="bacteria_yes" defaultChecked={job.tasks[props.match.params.task_id].bacteria === 'Yes'} name="bacteria" value="Yes" />
                                                        <label className="custom-control-label" htmlFor="bacteria_yes">Yes</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="bacteria_no" defaultChecked={job.tasks[props.match.params.task_id].bacteria === 'No'} name="bacteria" value="No" />
                                                        <label className="custom-control-label" htmlFor="bacteria_no">No</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="bacteria_na" defaultChecked={job.tasks[props.match.params.task_id].bacteria === 'N/A'} name="bacteria" value="N/A" />
                                                        <label className="custom-control-label" htmlFor="bacteria_na">N/A</label>
                                                    </div>
                                                </div>
                                                {Object.keys(this.state.errors).indexOf('bacteria') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['bacteria']}</div>
                                                )}
                                            </div>
                                            <div className="form-group required">
                                                <label className="text-primary"><strong>Legionella</strong></label>
                                                <div className="FormRadioCheckoutGroup">
                                                    <input type="radio" className="d-none" defaultChecked={true} name="legionella" value="" />
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="legionella_yes" defaultChecked={job.tasks[props.match.params.task_id].legionella === 'Yes'} name="legionella" value="Yes" />
                                                        <label className="custom-control-label" htmlFor="legionella_yes">Yes</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="legionella_no" defaultChecked={job.tasks[props.match.params.task_id].legionella === 'No'} name="legionella" value="No" />
                                                        <label className="custom-control-label" htmlFor="legionella_no">No</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="legionella_na" defaultChecked={job.tasks[props.match.params.task_id].legionella === 'N/A'} name="legionella" value="N/A" />
                                                        <label className="custom-control-label" htmlFor="legionella_na">N/A</label>
                                                    </div>
                                                </div>
                                                {Object.keys(this.state.errors).indexOf('legionella') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['legionella']}</div>
                                                )}
                                            </div>
                                            <hr/>
                                            <div className="form-group">
                                                <label className="text-primary"><strong>Comments</strong></label>
                                                <textarea name="comments" id="comments" defaultValue={job.tasks[props.match.params.task_id]['comments']} className="form-control" />
                                                {Object.keys(this.state.errors).indexOf('comments') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['comments']}</div>
                                                )}
                                            </div>
                                            <div className="FormButtons">
                                                <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                                {/*<button className="btn btn-primary">Next</button>*/}
                                                <button type="button" onClick={this.skipTask(props.match.params.task_id)} className="btn btn-outline-primary">Skip <i className="fa fa-fw fa-fast-forward" /></button>
                                                <button type="submit" className="btn btn-success">Complete Task <i className="fa fa-fw fa-check" /></button>
                                            </div>
                                        </form>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/water_sample/review`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Water sample
                                return (
                                    <>
                                        <h3>Water Sample: {job.tasks[props.match.params.task_id].location}</h3>
                                        {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                        )}
                                        {job.tasks[props.match.params.task_id].location_notes && (
                                            <div className="alert alert-info">
                                                <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                            <div className="alert alert-warning">
                                                <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status !== jobConstants.TASK_STATUS_SKIPPED && (
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <th>Bacteria</th>
                                                    <td>{job.tasks[props.match.params.task_id]['bacteria']}</td>
                                                </tr>
                                                <tr>
                                                    <th>Legionella</th>
                                                    <td>{job.tasks[props.match.params.task_id]['legionella']}</td>
                                                </tr>
                                                <tr>
                                                    <th>Comments</th>
                                                    <td>{job.tasks[props.match.params.task_id]['comments']}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                        <div className="text-center">
                                            <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                        </div>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/temperature`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Temperature
                                return (
                                    <>
                                        <h3>Temperature: {job.tasks[props.match.params.task_id].location}</h3>
                                        <form ref={this.taskForm} onSubmit={this.completeTask(props.match.params.task_id)}>
                                            {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                            )}
                                            {job.tasks[props.match.params.task_id].location_notes && (
                                                <div className="alert alert-info">
                                                    <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                                </div>
                                            )}
                                            {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                                <div className="alert alert-warning">
                                                    <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col-md-3 offset-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Supply</strong></label>
                                                        <select name="supply" className="form-control custom-select">
                                                            {this.supplyTypes.map((supply: any) => {
                                                                return (
                                                                    <option value={supply} defaultChecked={job.tasks[props.match.params.task_id]['supply'] === supply}>{supply}</option>
                                                                );
                                                            })}
                                                        </select>
                                                        {Object.keys(this.state.errors).indexOf('supply') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['supply']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Temperature</strong></label>
                                                        <div className="input-group">
                                                            <input type="number" className="form-control" name="temperature" defaultValue={job.tasks[props.match.params.task_id]['temperature']} aria-label="Temperature" aria-describedby="temperature-label" />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" id="temperature-label">&deg;C</span>
                                                            </div>
                                                        </div>
                                                        {Object.keys(this.state.errors).indexOf('temperature') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['temperature']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="form-group">
                                                <label className="text-primary"><strong>Comments</strong></label>
                                                <textarea name="comments" id="comments" defaultValue={job.tasks[props.match.params.task_id]['comments']} className="form-control" />
                                                {Object.keys(this.state.errors).indexOf('comments') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['comments']}</div>
                                                )}
                                            </div>
                                            <div className="FormButtons">
                                                <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                                {/*<button className="btn btn-primary">Next</button>*/}
                                                <button type="button" onClick={this.skipTask(props.match.params.task_id)} className="btn btn-outline-primary">Skip <i className="fa fa-fw fa-fast-forward" /></button>
                                                <button type="submit" className="btn btn-success">Complete Task <i className="fa fa-fw fa-check" /></button>
                                            </div>
                                        </form>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/temperature/review`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Temperature
                                return (
                                    <>
                                        <h3>Temperature: {job.tasks[props.match.params.task_id].location}</h3>
                                        {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                        )}
                                        {job.tasks[props.match.params.task_id].location_notes && (
                                            <div className="alert alert-info">
                                                <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                            <div className="alert alert-warning">
                                                <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status !== jobConstants.TASK_STATUS_SKIPPED && (
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <th>Supply</th>
                                                    <td>{job.tasks[props.match.params.task_id]['supply']}</td>
                                                </tr>
                                                <tr>
                                                    <th>Temperature</th>
                                                    <td>{job.tasks[props.match.params.task_id]['temperature']}&deg;C</td>
                                                </tr>
                                                <tr>
                                                    <th>Comments</th>
                                                    <td>{job.tasks[props.match.params.task_id]['comments']}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                        <div className="text-center">
                                            <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                        </div>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/hydro_tr_dose`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Hydro TR Dose
                                return (
                                    <>
                                        <h3>Hydro TR</h3>
                                        <form ref={this.taskForm} onSubmit={this.completeTask(props.match.params.task_id)}>
                                            {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                            )}
                                            {job.tasks[props.match.params.task_id].location_notes && (
                                                <div className="alert alert-info">
                                                    <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                                </div>
                                            )}
                                            {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                                <div className="alert alert-warning">
                                                    <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                                </div>
                                            )}
                                            <div className="form-group required">
                                                <label className="text-primary"><strong>Hydro TR dosed to Cold Water Storage Tank?</strong></label>
                                                <div className="FormRadioCheckoutGroup">
                                                    <input type="radio" className="d-none" defaultChecked={true} name="hydro_tr_dose" value="" />
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="hydro_tr_dose_yes" defaultChecked={job.tasks[props.match.params.task_id].hydro_tr_dose === 'Yes'} name="hydro_tr_dose" value="Yes" />
                                                        <label className="custom-control-label" htmlFor="hydro_tr_dose_yes">Yes</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="hydro_tr_dose_no" defaultChecked={job.tasks[props.match.params.task_id].hydro_tr_dose === 'No'} name="hydro_tr_dose" value="No" />
                                                        <label className="custom-control-label" htmlFor="hydro_tr_dose_no">No</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="hydro_tr_dose_na" defaultChecked={job.tasks[props.match.params.task_id].hydro_tr_dose === 'N/A'} name="hydro_tr_dose" value="N/A" />
                                                        <label className="custom-control-label" htmlFor="hydro_tr_dose_na">N/A</label>
                                                    </div>
                                                </div>
                                                {Object.keys(this.state.errors).indexOf('hydro_tr_dose') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['hydro_tr_dose']}</div>
                                                )}
                                            </div>
                                            <hr/>
                                            <div className="form-group">
                                                <label className="text-primary"><strong>Comments</strong></label>
                                                <textarea name="comments" id="comments" defaultValue={job.tasks[props.match.params.task_id]['comments']} className="form-control" />
                                                {Object.keys(this.state.errors).indexOf('comments') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['comments']}</div>
                                                )}
                                            </div>
                                            <div className="FormButtons">
                                                <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                                {/*<button className="btn btn-primary">Next</button>*/}
                                                <button type="button" onClick={this.skipTask(props.match.params.task_id)} className="btn btn-outline-primary">Skip <i className="fa fa-fw fa-fast-forward" /></button>
                                                <button type="submit" className="btn btn-success">Complete Task <i className="fa fa-fw fa-check" /></button>
                                            </div>
                                        </form>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/hydro_tr_dose/review`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Hydro TR Dose
                                return (
                                    <>
                                        <h3>Hydro TR</h3>
                                        {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                        )}
                                        {job.tasks[props.match.params.task_id].location_notes && (
                                            <div className="alert alert-info">
                                                <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                            <div className="alert alert-warning">
                                                <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status !== jobConstants.TASK_STATUS_SKIPPED && (
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <th>Hydro TR dosed to Cold Water Storage Tank?</th>
                                                    <td>{job.tasks[props.match.params.task_id]['hydro_tr_dose']}</td>
                                                </tr>
                                                <tr>
                                                    <th>Comments</th>
                                                    <td>{job.tasks[props.match.params.task_id]['comments']}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                        <div className="text-center">
                                            <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                        </div>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/sign-off`} render={(props: any) => {
                                // Displays the signature pad and input for the client to sign off.
                                return (
                                    <>
                                        <form onSubmit={this.completeJob()}>
                                            <p>Customer Signature</p>
                                            <div className="SignatureBox">
                                                <SignatureCanvas canvasProps={this.state.canvasProps} ref={this.signatureBox} penColor='black' />
                                            </div>
                                            {Object.keys(this.state.errors).indexOf('signature') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['signature']}</div>
                                            )}
                                            <div className="form-group">
                                                <label htmlFor="name">Customer Name</label>
                                                <input type="text" className="form-control" id="name" name="name"/>
                                                {Object.keys(this.state.errors).indexOf('name') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['name']}</div>
                                                )}
                                            </div>
                                            <div className="FormButtons">
                                                <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                                <button type="submit" className="btn btn-success">Complete Task <i className="fa fa-fw fa-check" /></button>
                                            </div>
                                        </form>
                                    </>
                                );
                            }} />
                            <Route render={() => {

                                let completed_tasks = 0;
                                // Count tasks completed/skipped to determine whether the job is ready to be completed.
                                job.tasks.forEach((task: any) => {
                                    if ([jobConstants.TASK_STATUS_COMPLETE, jobConstants.TASK_STATUS_QUEUED, jobConstants.TASK_STATUS_SKIPPED].indexOf(task.status) > -1) {
                                        completed_tasks++;
                                    }
                                });

                                return (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <Box title="Client Information" theme="dark-blue" icon="fa-user">
                                                    <address>
                                                        <strong>{job.client.name}</strong><br />
                                                        <span className="newlines">{job.client.address}</span><br />
                                                        {job.client.postCode}
                                                    </address>
                                                    <strong>{job.client.contact}</strong><br />
                                                    {job.client.tel}
                                                </Box>
                                            </div>
                                            <div className="col-md-7">
                                                <Box title="Job Tasks" theme="grey" icon="fa-tasks">
                                                    <ul className="BoxList">
                                                        {job.tasks.map((task: any, idx: any) => {
                                                            const { icon, name } = mapTaskStatus(task.status);
                                                            return (
                                                                <li key={idx}>
                                                                    <Link to={`/job/${job.id}/task/${idx}/${task.type}/${([jobConstants.JOB_STATUS_QUEUED, jobConstants.JOB_STATUS_FAILED, jobConstants.JOB_STATUS_COMPLETE].indexOf(job.status) > -1 || task.status === jobConstants.TASK_STATUS_COMPLETE ? 'review' : '')}`}>
                                                                        <span>
                                                                            <i className={`fa fa-fw ${icon}`} title={name} data-toggle="tooltip" />
                                                                        </span>
                                                                        <strong>
                                                                            {task.location}
                                                                        </strong>
                                                                        <span>
                                                                            <i className={`fa fa-fw fa-${(task.type == 'water_sample' ? 'flask' : (task.type == 'temperature' ? 'thermometer-full' : 'tint'))}`} />
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </Box>
                                                {job.completed_at && (
                                                    <Box title="Signed Off" theme="blue" icon="fa-tasks">
                                                        <img src={job.signature} className="img-fluid" />
                                                        <p className="m-0 mt-3"><strong>Signed:</strong> {job.signed_by} <strong>on</strong> {moment(job.completed_at).format('DD/MM/YYYY HH:mm')}</p>
                                                    </Box>
                                                )}
                                                <div className="d-flex">
                                                    <Link to={`/`} className="btn btn-outline-secondary mr-auto">Back</Link>
                                                    {completed_tasks == job.tasks.length && [jobConstants.JOB_STATUS_QUEUED, jobConstants.JOB_STATUS_COMPLETE, jobConstants.JOB_STATUS_FAILED].indexOf(job.status) === -1 && (
                                                        <Link to={`/job/${job.id}/sign-off`} className="btn btn-primary">Sign Off <i className="fa fa-fw fa-pencil" /></Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }} />
                        </Switch>
                    </div>
                </div>
            </JobLayout>
        );
    }
}

function mapStateToProps(state: any) {
    const { jobs } = state;
    return {
        job: jobs.item
    };
}

const connectedProgrammeVisitForm = connect(mapStateToProps)(ProgrammeVisitForm);
export { connectedProgrammeVisitForm as ProgrammeVisitForm };