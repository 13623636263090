import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    error,
    clear,
    onlineStatus
};

function success(message: any) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message: any) {
    return { type: alertConstants.ERROR, message };
}

function clear() {
    return { type: alertConstants.CLEAR };
}

function onlineStatus(online: any) {
    document.documentElement.classList.remove('status-online');
    document.documentElement.classList.remove('status-offline');
    document.documentElement.classList.add('status-' + (online ? 'online' : 'offline'));
    return { type: alertConstants.ONLINE_STATUS, online };
}