import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { history } from '../../_helpers';
import './index.scss';

class JobLayout extends React.Component<any, any> {

    render() {
        const { alert } = this.props;
        const Header = this.props.header;
        return (
            <div className="JobLayout">
                <header id="header">
                    <div id="header-back">
                        <Link to={this.props.backLink ? this.props.backLink : '/'} className="btn"><i className="fa fa-caret-left" /> Back</Link>
                    </div>
                    <div id="header-title">
                        <Header />
                    </div>
                </header>
                <section id="content">
                    {alert.message && (
                        <div className="container">
                            <div className={`alert ${alert.type} alert-dismissible fade show`} role="alert">
                                {alert.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    )}
                    {this.props.children}
                </section>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedJobLayout = connect(mapStateToProps)(JobLayout);
export { connectedJobLayout as JobLayout };