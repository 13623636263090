export function getAPIDomain() {
    let url = (process.env.REACT_APP_API_DOMAIN ? process.env.REACT_APP_API_DOMAIN : 'http://safewater.test');
    if (window.location.href.indexOf('.uk') > -1) {
        url = url.replace('.eu', '.uk');
    }
    return url;
}
export function configureFetch() {

    const realFetch = window.fetch;
    (window as any).API_DOMAIN = getAPIDomain();
    window.fetch = (url: string, opts: any) => {

        opts = (opts ? opts : {});
        opts.mode = 'cors';
        opts.headers = (opts.headers ? opts.headers : {});

        opts.headers['Content-Type'] = 'application/json';
        opts.headers['Accept'] = 'application/json';

        if (url.indexOf('http') === -1) {
            url = (window as any).API_DOMAIN + url;
        }

        return realFetch(url, opts);

    };

}