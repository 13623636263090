import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
const loggerMiddleware = createLogger();

const middleware: any = [
    thunkMiddleware,
    process.env.REACT_APP_ENV !== 'production' && loggerMiddleware,
].filter(Boolean);

export const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);