import { jobConstants } from '../_constants';

export function jobs(state = {}, action: any) {
    switch (action.type) {

        //

        case jobConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case jobConstants.GETALL_SUCCESS:
            return {
                items: action.jobs,
                lastSyncDate: localStorage.getItem('last_sync_date'),
                loading: false
            };
        case jobConstants.GETALL_FAILURE:
            return {
                error: action.error,
                loading: false
            };

        //

        case jobConstants.GETONE_REQUEST:
            return {
                loading: true
            };
        case jobConstants.GETONE_SUCCESS:
            return {
                item: action.job,
                lastSyncDate: localStorage.getItem('last_sync_date'),
                loading: false
            };
        case jobConstants.GETONE_FAILURE:
            return {
                error: action.error,
                loading: false
            };

        //

        case jobConstants.SAVE_REQUEST:
            return {
                loading: true
            };
        case jobConstants.SAVE_SUCCESS:
            return {
                item: action.job,
                loading: false
            };
        case jobConstants.SAVE_FAILURE:
            return {
                error: action.error,
                loading: false
            };

        //

        case jobConstants.SYNC_REQUEST:
            return {
                syncing: true,
                lastSyncDate: localStorage.getItem('last_sync_date')
            };
        case jobConstants.SYNC_SUCCESS:
            return {
                syncing: false,
                items: action.jobs,
                lastSyncDate: localStorage.getItem('last_sync_date')
            };
        case jobConstants.SYNC_FAILURE:
            return {
                syncing: false,
                error: action.error
            };

        //

        default:
            return state
    }
}