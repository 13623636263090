import * as userService from './user.service';
export * from './user.service';
export * from './job.service';
export * from './fileSystem.service';
export * from './site.service';
export * from './testResult.service';
export * from './fileSystem.service';

export function handleResponse(response: any) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 404) {
                return Promise.reject('Not found!');
            }
            if (response.status === 401) {
                userService.logout();
                if (location.pathname !== '/login') {
                    return location.reload(true);
                }
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return (typeof data === 'object' && 'data' in data ? data.data : data);
    }).catch((error: any) => {
        return Promise.reject(error);
    });
}