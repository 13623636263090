import React from 'react';
import { connect } from 'react-redux';
import { InternalLayout } from '../../_layouts';
import { Link } from 'react-router-dom';
import { Box } from '../../_components';
import { jobActions } from '../../_actions';
import { jobConstants, mapJobStatus } from '../../_constants';
import moment from 'moment';

import './index.scss';

class Route extends React.Component<any, any> {

    componentDidMount() {
        const { dispatch, jobSyncing, jobSyncFailed, fsReady } = this.props;
        if (fsReady && !jobSyncing && !jobSyncFailed) {
            dispatch(jobActions.sync());
        }
    }

    componentDidUpdate(prevProps: any) {
        const { dispatch, jobs, jobSyncing, jobSyncFailed, fsReady } = this.props;
        if (fsReady && !jobSyncing && jobs === undefined && !jobSyncFailed) {
            dispatch(jobActions.sync());
        }
    }

    render() {
        const { jobs, jobSyncing } = this.props;

        const weekCommencing = this.props.match.params.week_commencing.replace(/-/g, '/');
        const route_id = this.props.match.params.route_id;
        let route = '';
        const currentJobs = (jobs ? jobs.filter((job: any) => {
            if (!route) {
                route = job.route;
            }
            let wc = moment(job.week_commencing, 'DD/MM/YYYY').startOf('isoWeek').format('DD/MM/YYYY');
            return job.route_id == route_id && wc == weekCommencing;
        }).sort((a: any, b: any) => {
            return moment(a.week_commencing, 'DD/MM/YYYY').diff(moment(b.week_commencing, 'DD/MM/YYYY'));
        }) : false);

        return (
            <InternalLayout>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Box title={`${route} - WC ${weekCommencing}`} theme="blue" icon="fa-briefcase">
                                {jobSyncing ? (
                                    <div className="Circle">
                                        <div className="Wave" />
                                    </div>
                                ) : (currentJobs.length ? (
                                    <ul className="BoxList Scrollable">
                                    {currentJobs.map((job: any) => {
                                        const { icon, name } = mapJobStatus(job.status);
                                        let completed_tasks = 0;
                                        job.tasks.forEach((task: any) => {
                                            if ([jobConstants.TASK_STATUS_COMPLETE, jobConstants.TASK_STATUS_QUEUED, jobConstants.TASK_STATUS_SKIPPED].indexOf(task.status) > -1) {
                                                completed_tasks++;
                                            }
                                        });
                                        return (
                                            <li key={`job_${job.id}`} className={`job-status-${job.status}`}>
                                                <Link to={`/job/${job.id}`}>
                                                    <span>
                                                        <i className={`fa ${icon}`} title={name} data-toggle="tooltip" />
                                                    </span>
                                                    <strong>
                                                        {job.site.company} - {job.site.name}
                                                        <small>({completed_tasks}/{job.tasks.length} task{job.tasks.length == 1 ? '' : 's'}) - {job.week_commencing}</small>
                                                    </strong>
                                                    <i className="fa fa-caret-right" />
                                                </Link>
                                            </li>
                                        );
                                    })}
                                    </ul>
                                ) : (<p className="text-center">You do not currently have any jobs for this week.</p>))}
                            </Box>
                        </div>
                    </div>
                </div>
            </InternalLayout>
        );
    }
}

function mapStateToProps(state: any) {
    const { authentication, fileSystem, jobs } = state;
    return {
        user: authentication.user,
        fsReady: fileSystem.ready,
        jobSyncing: jobs.syncing,
        jobSyncFailed: !!jobs.error,
        jobs: jobs.items
    };
}

const connectedRoute = connect(mapStateToProps)(Route);
export { connectedRoute as Route };