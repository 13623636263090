import { jobConstants } from '../_constants';

export function calendar(state = {}, action: any) {
    switch (action.type) {

        //

        case jobConstants.GETCALENDAR_REQUEST:
            return {
                calendar_loading: true
            };
        case jobConstants.GETCALENDAR_SUCCESS:
            return {
                calendar_items: action.calendar_items,
                lastSyncDate: localStorage.getItem('last_sync_date'),
                calendar_loading: false
            };
        case jobConstants.GETCALENDAR_FAILURE:
            return {
                calendar_error: action.error,
                calendar_loading: false
            };


        //

        default:
            return state
    }
}