import React from 'react';
import { connect } from 'react-redux';
import { Box } from '../../../../_components/Box';
import { mapTaskStatus } from '../../../../_constants';
import { Switch, Route, Link } from 'react-router-dom';
import { JobLayout } from '../../../../_layouts';
import { jobConstants } from '../../../../_constants';
import { jobActions } from '../../../../_actions';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';

import './index.scss';

class ServiceCallForm extends React.Component<any, any> {

    private signatureBox: any;
    private taskForm: any;

    private resizeSignatureCanvas() {
        if (this.signatureBox.current && (!('width' in this.state.canvasProps) || this.state.canvasProps.width != this.signatureBox.current._canvas.parentElement.clientWidth)) {
            this.state.canvasProps.width = this.signatureBox.current._canvas.parentElement.clientWidth;
            this.setState(this.state);
        }
    }

    constructor(props: any) {
        super(props);

        this.state = {
            errors: {},
            canvasProps: {
                height: '200px'
            }
        };

        // bind all handlers to the component to become state aware.
        this.completeTask = this.completeTask.bind(this);
        this.completeJob = this.completeJob.bind(this);
        this.skipTask = this.skipTask.bind(this);

        // create references for elements to bind to.
        this.signatureBox = React.createRef();
        this.taskForm = React.createRef();

        // listen for any resize events to resize the signature canvas.
        window.addEventListener('resize', this.resizeSignatureCanvas.bind(this), false);
    }

    componentDidUpdate() {
        this.resizeSignatureCanvas();
    }

    componentWillUnmount() {
        // kill the resize event handler.
        window.removeEventListener('resize', this.resizeSignatureCanvas);
    }

    componentDidMount() {
        this.resizeSignatureCanvas();
    }

    /**
     * Validate and save a task.
     * @param task_id: associated task.
     * @returns function
     */
    completeTask(task_id: any) {
        return (e: any) => {
            e.preventDefault();

            // Get all the form data.
            const fd = new FormData(e.target);

            let errors: any = {},
                me = this;

            // Ensure all fields that are required, have a value.
            fd.forEach((value: any, key: any) => {
                if (value.length <= 0 && ['comments'].indexOf(key) === -1) {
                    errors[key] = 'This field is required';
                }
            });

            let task = me.props.job.tasks[task_id];
            if (task.type == 'fail_safe') {
                var result = fd.get('result'),
                    comments = fd.get('comments');
                if (result == 'Fail' && !comments) {
                    errors['comments'] = 'Please specify why this task Failed';
                }
            }

            // Update the error state.
            this.setState({
                errors
            });

            // If there weren't any errors, proceed with completing the task.
            if (Object.keys(errors).length === 0) {
                // Update the task.
                fd.forEach((value: any, key: any) => {
                    task[key] = value;
                });
                // Set the task status.
                task.status = jobConstants.TASK_STATUS_COMPLETE;
                task.completed_at = moment.now();
                // Update the job with the updated task.
                me.props.job.tasks[task_id] = task;
                // Mark the job as open, now we've started working on it.
                me.props.job.status = jobConstants.JOB_STATUS_OPEN;
                // Now dispatch the job save event to store the changes.
                me.props.dispatch(jobActions.save(me.props.job, 'Task completed successfully'));
            }
        }
    }

    /**
     * Validate and mark a job as completed.
     * @returns function
     */
    completeJob() {
        return (e: any) => {
            e.preventDefault();

            // Get all the form data.
            const fd = new FormData(e.target);

            let errors: any = {},
                me = this,
                name: any = fd.get('name');

            // Ensure the client has entered their name.
            if (name === null || name.length <= 0) {
                errors['name'] = 'Please type in your name';
            }

            // Ensure the client has signed.
            if (this.signatureBox.current.isEmpty()) {
                errors['signature'] = 'Please provide a signature';
            }

            // Update the error state.
            this.setState({
                errors
            });

            // If there weren't any errors, proceed with completing the job.
            if (Object.keys(errors).length === 0) {
                // Mark the job as queued, to be sent when possible.
                me.props.job.status = jobConstants.JOB_STATUS_QUEUED;
                // Set the clients name and signature against the job.
                me.props.job.signed_by = name;
                me.props.job.signature = this.signatureBox.current.toDataURL();
                me.props.job.completed_at = moment.now();
                // Now dispatch the job save event to store the changes.
                me.props.dispatch(jobActions.save(me.props.job, 'Job successfully queued'));
            }
        };
    }

    /**
     * Validate and mark a task as skipped.
     * @returns function
     */
    skipTask(task_id: any) {
        return (e: any) => {
            e.preventDefault();

            // Get all the form data.
            const fd = new FormData(this.taskForm.current);

            let errors: any = {},
                me = this,
                comments: any = fd.get('comments');

            // Ensure a comment has been specified.
            if (comments === null || comments.length <= 0) {
                errors['comments'] = 'Please state why this task was skipped.';
            }

            // Update the error state.
            this.setState({
                errors
            });

            // If there weren't any errors, proceed with skipping the task.
            if (Object.keys(errors).length === 0) {
                // Get the associated task.
                let task = me.props.job.tasks[task_id];
                // Mark the task as skipped.
                task.status = jobConstants.TASK_STATUS_SKIPPED;
                task.completed_at = moment.now();
                // Set the task comments.
                task.comments = comments;
                // Update the job with the updated task.
                me.props.job.tasks[task_id] = task;
                // Now dispatch the job save event to store the changes.
                me.props.dispatch(jobActions.save(me.props.job, 'Task skipped'));
            }
        }
    }

    render() {
        const { job } = this.props;
        return (
            <JobLayout header={() => {
                return <h1>Microbiological Sample Report</h1>;
            }}>
                <div className="Form">
                    <div className="container">
                        <Switch>
                            <Route path={`/job/:job_id/task/:task_id/treated_water_conditions`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Treated Water Conditions
                                return (
                                    <>
                                        <h3>Treated Water Conditions</h3>
                                        <form ref={this.taskForm} onSubmit={this.completeTask(props.match.params.task_id)}>
                                            {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                            )}
                                            {job.tasks[props.match.params.task_id].location_notes && (
                                                <div className="alert alert-info">
                                                    <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                                </div>
                                            )}
                                            {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                                <div className="alert alert-warning">
                                                    <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                                </div>
                                            )}
                                            <h4 className="mb-3">{job.tasks[props.match.params.task_id].description}</h4>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Control</strong></label>
                                                        <input type="number" className="form-control" id="control" name="control" defaultValue={job.tasks[props.match.params.task_id].control} />
                                                        {Object.keys(this.state.errors).indexOf('control') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['control']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Sample 1</strong></label>
                                                        <input type="number" className="form-control" id="values_1" name="values_1" defaultValue={job.tasks[props.match.params.task_id].values_1} />
                                                        {Object.keys(this.state.errors).indexOf('values_1') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['values_1']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Sample 2</strong></label>
                                                        <input type="number" className="form-control" id="values_2" name="values_2" defaultValue={job.tasks[props.match.params.task_id].values_2} />
                                                        {Object.keys(this.state.errors).indexOf('values_2') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['values_2']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Sample 3</strong></label>
                                                        <input type="number" className="form-control" id="values_3" name="values_3" defaultValue={job.tasks[props.match.params.task_id].values_3} />
                                                        {Object.keys(this.state.errors).indexOf('values_3') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['values_3']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="form-group">
                                                <label className="text-primary"><strong>Comments</strong></label>
                                                <textarea name="comments" id="comments" defaultValue={job.tasks[props.match.params.task_id]['comments']} className="form-control" />
                                                {Object.keys(this.state.errors).indexOf('comments') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['comments']}</div>
                                                )}
                                            </div>
                                            <div className="FormButtons">
                                                <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                                {/*<button className="btn btn-primary">Next</button>*/}
                                                <button type="button" onClick={this.skipTask(props.match.params.task_id)} className="btn btn-outline-primary">Skip <i className="fa fa-fw fa-fast-forward" /></button>
                                                <button type="submit" className="btn btn-success">Complete Task <i className="fa fa-fw fa-check" /></button>
                                            </div>
                                        </form>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/treated_water_conditions/review`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Treated Water Conditions
                                return (
                                    <>
                                        <h3>Treated Water Conditions</h3>
                                        {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                        )}
                                        {job.tasks[props.match.params.task_id].location_notes && (
                                            <div className="alert alert-info">
                                                <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                            <div className="alert alert-warning">
                                                <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                            </div>
                                        )}
                                        <h4 className="mb-3">{job.tasks[props.match.params.task_id].description}</h4>
                                        {job.tasks[props.match.params.task_id].status !== jobConstants.TASK_STATUS_SKIPPED && (
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <th>Control</th>
                                                    <td>{job.tasks[props.match.params.task_id]['control']}</td>
                                                </tr>
                                                <tr>
                                                    <th>Sample 1</th>
                                                    <td>{job.tasks[props.match.params.task_id]['values_1']}</td>
                                                </tr>
                                                <tr>
                                                    <th>Sample 2</th>
                                                    <td>{job.tasks[props.match.params.task_id]['values_2']}</td>
                                                </tr>
                                                <tr>
                                                    <th>Sample 3</th>
                                                    <td>{job.tasks[props.match.params.task_id]['values_3']}</td>
                                                </tr>
                                                <tr>
                                                    <th>Comments</th>
                                                    <td>{job.tasks[props.match.params.task_id]['comments']}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                        <div className="text-center">
                                            <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                        </div>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/input_water_conditions`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Input Water Conditions
                                return (
                                    <>
                                        <h3>Input Water Conditions</h3>
                                        <form ref={this.taskForm} onSubmit={this.completeTask(props.match.params.task_id)}>
                                            {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                            )}
                                            {job.tasks[props.match.params.task_id].location_notes && (
                                                <div className="alert alert-info">
                                                    <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                                </div>
                                            )}
                                            {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                                <div className="alert alert-warning">
                                                    <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                                </div>
                                            )}
                                            <h4 className="mb-3">{job.tasks[props.match.params.task_id].description}</h4>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>pH</strong></label>
                                                        <input type="number" className="form-control" id="ph" name="ph" defaultValue={job.tasks[props.match.params.task_id].ph} />
                                                        {Object.keys(this.state.errors).indexOf('ph') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['ph']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Ca/T.H.</strong></label>
                                                        <input type="number" className="form-control" id="cath" name="cath" defaultValue={job.tasks[props.match.params.task_id].cath} />
                                                        {Object.keys(this.state.errors).indexOf('cath') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['cath']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>M.Alk</strong></label>
                                                        <input type="number" className="form-control" id="malk" name="malk" defaultValue={job.tasks[props.match.params.task_id].malk} />
                                                        {Object.keys(this.state.errors).indexOf('malk') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['malk']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Cl</strong></label>
                                                        <input type="number" className="form-control" id="cl" name="cl" defaultValue={job.tasks[props.match.params.task_id].cl} />
                                                        {Object.keys(this.state.errors).indexOf('cl') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['cl']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Iron</strong></label>
                                                        <input type="number" className="form-control" id="iron" name="iron" defaultValue={job.tasks[props.match.params.task_id].iron} />
                                                        {Object.keys(this.state.errors).indexOf('iron') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['iron']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>T.D.S</strong></label>
                                                        <input type="number" className="form-control" id="tds" name="tds" defaultValue={job.tasks[props.match.params.task_id].tds} />
                                                        {Object.keys(this.state.errors).indexOf('tds') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['tds']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group required">
                                                        <label className="text-primary"><strong>Temp</strong></label>
                                                        <input type="number" className="form-control" id="temp" name="temp" defaultValue={job.tasks[props.match.params.task_id].temp} />
                                                        {Object.keys(this.state.errors).indexOf('temp') > -1 && (
                                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['temp']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="form-group">
                                                <label className="text-primary"><strong>Comments</strong></label>
                                                <textarea name="comments" id="comments" defaultValue={job.tasks[props.match.params.task_id]['comments']} className="form-control" />
                                                {Object.keys(this.state.errors).indexOf('comments') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['comments']}</div>
                                                )}
                                            </div>
                                            <div className="FormButtons">
                                                <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                                {/*<button className="btn btn-primary">Next</button>*/}
                                                <button type="button" onClick={this.skipTask(props.match.params.task_id)} className="btn btn-outline-primary">Skip <i className="fa fa-fw fa-fast-forward" /></button>
                                                <button type="submit" className="btn btn-success">Complete Task <i className="fa fa-fw fa-check" /></button>
                                            </div>
                                        </form>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/temperature/review`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Take temperature
                                return (
                                    <>
                                        <h3>Take Temperature</h3>
                                            {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                            )}
                                            {job.tasks[props.match.params.task_id].location_notes && (
                                                <div className="alert alert-info">
                                                    <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                                </div>
                                            )}
                                            {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                                <div className="alert alert-warning">
                                                    <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                                </div>
                                            )}
                                            {job.tasks[props.match.params.task_id].status !== jobConstants.TASK_STATUS_SKIPPED && (
                                                <table className="table">
                                                    <tbody>
                                                    <tr>
                                                        <th>Temperature</th>
                                                        <td>{job.tasks[props.match.params.task_id]['temperature']}&deg;C</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Comments</th>
                                                        <td>{job.tasks[props.match.params.task_id]['comments']}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        <div className="text-center">
                                            <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                        </div>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/fail_safe`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Fail/Safe
                                return (
                                    <>
                                        <h3>Fail/Safe Test</h3>
                                        <form ref={this.taskForm} onSubmit={this.completeTask(props.match.params.task_id)}>
                                            {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                            )}
                                            {job.tasks[props.match.params.task_id].location_notes && (
                                                <div className="alert alert-info">
                                                    <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                                </div>
                                            )}
                                            {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                                <div className="alert alert-warning">
                                                    <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                                </div>
                                            )}
                                            <div className="form-group required">
                                                <label className="text-primary"><strong>{job.tasks[props.match.params.task_id].description}</strong></label>
                                                <div className="FormRadioCheckoutGroup">
                                                    <input type="radio" className="d-none" defaultChecked={true} name="result" value="" />
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="result_pass" defaultChecked={job.tasks[props.match.params.task_id].result === 'Pass'} name="result" value="Pass" />
                                                        <label className="custom-control-label" htmlFor="result_pass">Pass</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" className="custom-control-input" id="result_fail" defaultChecked={job.tasks[props.match.params.task_id].result === 'Fail'} name="result" value="Fail" />
                                                        <label className="custom-control-label" htmlFor="result_fail">Fail</label>
                                                    </div>
                                                </div>
                                                {Object.keys(this.state.errors).indexOf('result') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['result']}</div>
                                                )}
                                            </div>
                                            <hr/>
                                            <div className="form-group">
                                                <label className="text-primary"><strong>Comments</strong></label>
                                                <textarea name="comments" id="comments" defaultValue={job.tasks[props.match.params.task_id]['comments']} className="form-control" />
                                                {Object.keys(this.state.errors).indexOf('comments') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['comments']}</div>
                                                )}
                                            </div>
                                            <div className="FormButtons">
                                                <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                                {/*<button className="btn btn-primary">Next</button>*/}
                                                <button type="button" onClick={this.skipTask(props.match.params.task_id)} className="btn btn-outline-primary">Skip <i className="fa fa-fw fa-fast-forward" /></button>
                                                <button type="submit" className="btn btn-success">Complete Task <i className="fa fa-fw fa-check" /></button>
                                            </div>
                                        </form>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/fail_safe/review`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Fail/Safe
                                return (
                                    <>
                                        <h3>Fail/Safe Test</h3>
                                        {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                        )}
                                        {job.tasks[props.match.params.task_id].location_notes && (
                                            <div className="alert alert-info">
                                                <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                            <div className="alert alert-warning">
                                                <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status !== jobConstants.TASK_STATUS_SKIPPED && (
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <th>Result</th>
                                                    <td>{job.tasks[props.match.params.task_id]['result']}</td>
                                                </tr>
                                                <tr>
                                                    <th>Comments</th>
                                                    <td>{job.tasks[props.match.params.task_id]['comments']}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                        <div className="text-center">
                                            <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                        </div>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/flow_rates`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Flow Rates
                                return (
                                    <>
                                        <h3>Flow Rate</h3>
                                        <form ref={this.taskForm} onSubmit={this.completeTask(props.match.params.task_id)}>
                                            {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                            )}
                                            {job.tasks[props.match.params.task_id].location_notes && (
                                                <div className="alert alert-info">
                                                    <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                                </div>
                                            )}
                                            {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                                <div className="alert alert-warning">
                                                    <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                                </div>
                                            )}
                                            <div className="form-group required">
                                                <label className="text-primary"><strong>Flow Rate</strong></label>
                                                <div className="input-group" style={{'maxWidth': '170px', 'margin': '0 auto'}}>
                                                    <input type="number" className="form-control" name="flow_rate" defaultValue={job.tasks[props.match.params.task_id]['flow_rate']} aria-label="flow_rate" aria-describedby="flow_rate-label" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="flow_rate-label">ltrs/min</span>
                                                    </div>
                                                </div>
                                                {Object.keys(this.state.errors).indexOf('flow_rate') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['flow_rate']}</div>
                                                )}
                                            </div>
                                            <hr/>
                                            <div className="form-group">
                                                <label className="text-primary"><strong>Comments</strong></label>
                                                <textarea name="comments" id="comments" defaultValue={job.tasks[props.match.params.task_id]['comments']} className="form-control" />
                                                {Object.keys(this.state.errors).indexOf('comments') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['comments']}</div>
                                                )}
                                            </div>
                                            <div className="FormButtons">
                                                <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                                {/*<button className="btn btn-primary">Next</button>*/}
                                                <button type="button" onClick={this.skipTask(props.match.params.task_id)} className="btn btn-outline-primary">Skip <i className="fa fa-fw fa-fast-forward" /></button>
                                                <button type="submit" className="btn btn-success">Complete Task <i className="fa fa-fw fa-check" /></button>
                                            </div>
                                        </form>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/task/:task_id/flow_rates/review`} exact={true} render={(props: any) => {
                                // Displays the task form for the user to complete.
                                // Flow Rates
                                return (
                                    <>
                                        <h3>Flow Rate</h3>
                                        {Object.keys(this.state.errors).indexOf('generic') > -1 && (
                                            <div className="alert alert-xs alert-danger notification">{this.state.errors['generic']}</div>
                                        )}
                                        {job.tasks[props.match.params.task_id].location_notes && (
                                            <div className="alert alert-info">
                                                <i className="fa fa-info-circle" /> <strong>Note:</strong> {job.tasks[props.match.params.task_id].location_notes}
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status === jobConstants.TASK_STATUS_SKIPPED && (
                                            <div className="alert alert-warning">
                                                <i className="fa fa-fast-forward" /> <strong>Note:</strong> This task has been skipped.<br /><br />You can still complete the task at a later time.
                                            </div>
                                        )}
                                        {job.tasks[props.match.params.task_id].status !== jobConstants.TASK_STATUS_SKIPPED && (
                                            <table className="table">
                                                <tbody>
                                                <tr>
                                                    <th>Flow Rate</th>
                                                    <td>{job.tasks[props.match.params.task_id]['flow_rate']} ltrs/min</td>
                                                </tr>
                                                <tr>
                                                    <th>Comments</th>
                                                    <td>{job.tasks[props.match.params.task_id]['comments']}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                        <div className="text-center">
                                            <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                        </div>
                                    </>
                                );
                            }} />
                            <Route path={`/job/:job_id/sign-off`} render={(props: any) => {
                                // Displays the signature pad and input for the client to sign off.
                                return (
                                    <>
                                        <form onSubmit={this.completeJob()}>
                                            <p>Customer Signature</p>
                                            <div className="SignatureBox">
                                                <SignatureCanvas canvasProps={this.state.canvasProps} ref={this.signatureBox} penColor='black' />
                                            </div>
                                            {Object.keys(this.state.errors).indexOf('signature') > -1 && (
                                                <div className="alert alert-xs alert-danger notification">{this.state.errors['signature']}</div>
                                            )}
                                            <div className="form-group">
                                                <label htmlFor="name">Customer Name</label>
                                                <input type="text" className="form-control" id="name" name="name"/>
                                                {Object.keys(this.state.errors).indexOf('name') > -1 && (
                                                    <div className="alert alert-xs alert-danger notification">{this.state.errors['name']}</div>
                                                )}
                                            </div>
                                            <div className="FormButtons">
                                                <Link to={`/job/${job.id}`} className="btn btn-outline-secondary">Back</Link>
                                                <button type="submit" className="btn btn-success">Complete Task <i className="fa fa-fw fa-check" /></button>
                                            </div>
                                        </form>
                                    </>
                                );
                            }} />
                            <Route render={() => {

                                let completed_tasks = 0;
                                // Count tasks completed/skipped to determine whether the job is ready to be completed.
                                job.tasks.forEach((task: any) => {
                                    if ([jobConstants.TASK_STATUS_COMPLETE, jobConstants.TASK_STATUS_QUEUED, jobConstants.TASK_STATUS_SKIPPED].indexOf(task.status) > -1) {
                                        completed_tasks++;
                                    }
                                });

                                return (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <Box title="Client Information" theme="dark-blue" icon="fa-user">
                                                    <address>
                                                        <strong>{job.client.name}</strong><br />
                                                        <span className="newlines">{job.client.address}</span><br />
                                                        {job.client.postCode}
                                                    </address>
                                                    <strong>{job.client.contact}</strong><br />
                                                    {job.client.tel}
                                                </Box>
                                            </div>
                                            <div className="col-md-7">
                                                <Box title="Job Tasks" theme="grey" icon="fa-tasks">
                                                    <ul className="BoxList">
                                                        {job.tasks.map((task: any, idx: any) => {
                                                            const { icon, name } = mapTaskStatus(task.status);
                                                            return (
                                                                <li key={idx}>
                                                                    <Link to={`/job/${job.id}/task/${idx}/${task.type}/${([jobConstants.JOB_STATUS_QUEUED, jobConstants.JOB_STATUS_FAILED, jobConstants.JOB_STATUS_COMPLETE].indexOf(job.status) > -1 || task.status === jobConstants.TASK_STATUS_COMPLETE ? 'review' : '')}`}>
                                                                        <span>
                                                                            <i className={`fa fa-fw ${icon}`} title={name} data-toggle="tooltip" />
                                                                        </span>
                                                                        <strong>
                                                                            {task.description}
                                                                        </strong>
                                                                    </Link>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </Box>
                                                {job.completed_at && (
                                                    <Box title="Signed Off" theme="blue" icon="fa-tasks">
                                                        <img src={job.signature} className="img-fluid" />
                                                        <p className="m-0 mt-3"><strong>Signed:</strong> {job.signed_by} <strong>on</strong> {moment(job.completed_at).format('DD/MM/YYYY HH:mm')}</p>
                                                    </Box>
                                                )}
                                                <div className="d-flex">
                                                    <Link to={`/`} className="btn btn-outline-secondary mr-auto">Back</Link>
                                                    {completed_tasks == job.tasks.length && [jobConstants.JOB_STATUS_QUEUED, jobConstants.JOB_STATUS_COMPLETE, jobConstants.JOB_STATUS_FAILED].indexOf(job.status) === -1 && (
                                                        <Link to={`/job/${job.id}/sign-off`} className="btn btn-primary">Sign Off <i className="fa fa-fw fa-pencil" /></Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }} />
                        </Switch>
                    </div>
                </div>
            </JobLayout>
        );
    }
}

function mapStateToProps(state: any) {
    const { jobs } = state;
    return {
        job: jobs.item
    };
}

const connectedServiceCallForm = connect(mapStateToProps)(ServiceCallForm);
export { connectedServiceCallForm as ServiceCallForm };