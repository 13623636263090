import React from 'react';
import { connect } from 'react-redux';
import { jobActions } from '../../_actions';
import { Loader } from '../../_components';
import {
    CalorifierAnnualInspectionForm,
    L8TemperatureMonitoringForm,
    MicrobiologicalSampleForm,
    ProgrammeVisitForm, ServiceCallForm,
    ThermostaticMixingValveServiceForm,
    GenericForm
} from './Forms';

import './index.scss';
import {history} from "../../_helpers";

class Job extends React.Component<any, any> {

    //HTML5 FileSystem

    componentDidUpdate(){
        let { loading, error } = this.props;
        if (!loading && !!error) {
            history.push('/');
        }
    }

    componentDidMount() {
        const { job_id } = this.props.match.params;

        if (!job_id) return;

        const { dispatch } = this.props;

        dispatch(jobActions.getOne(job_id));
    }

    render() {

        const { job, loading, error } = this.props;

        if (error) {
            console.log(error);
        }

        if (typeof loading === 'undefined' || loading) {
            return <Loader />;
        }

        let Form:any = GenericForm;
        // if (job) {
        //     switch (job.job_type) {
        //         case 'microbiological_sampling':
        //             Form = MicrobiologicalSampleForm;
        //             break;
        //         case 'l8_temperature_monitoring':
        //             Form = L8TemperatureMonitoringForm;
        //             break;
        //         case 'programme_visit':
        //             Form = ProgrammeVisitForm;
        //             break;
        //         case 'thermostatic_mixing_valve_service':
        //             Form = ThermostaticMixingValveServiceForm;
        //             break;
        //         case 'service_call':
        //             Form = ServiceCallForm;
        //             break;
        //         case 'calorifier_annual_inspection':
        //             Form = CalorifierAnnualInspectionForm;
        //             break;
        //     }
        // }

        return (
            <Form />
        );
    }
}

function mapStateToProps(state: any) {
    const { jobs } = state;
    return {
        job: jobs.item,
        error: jobs.error,
        loading: jobs.loading
    };
}

const connectedJob = connect(mapStateToProps)(Job);
export { connectedJob as Job };