import { testResultConstants } from '../_constants';

export function testResults(state = {}, action: any) {
    switch (action.type) {

        //

        case testResultConstants.GETFAILURES_REQUEST:
            return {
                loading: true
            };
        case testResultConstants.GETFAILURES_SUCCESS:
            return {
                failures: action.failures,
                loading: false
            };
        case testResultConstants.GETFAILURES_FAILURE:
            return {
                error: action.error,
                loading: false
            };

        //

        default:
            return state
    }
}