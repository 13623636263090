import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { history, bugsnagClient } from '../../_helpers';
import { alertActions, fileSystemActions, jobActions} from '../../_actions';
import { PrivateRoute } from '../../_components';
import { Login } from '../Login';
import { Dashboard } from '../Dashboard';
import { Job } from '../Job';
import { Route as JobRoute } from '../Route';
import { Calendar } from '../Calendar';

import './index.scss';

class App extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        const { dispatch } = this.props;

        dispatch(fileSystemActions.requestAccess());

        (window as any).addEventListener('online', () => dispatch(alertActions.onlineStatus((window as any).navigator.onLine)));
        (window as any).addEventListener('offline', () => dispatch(alertActions.onlineStatus((window as any).navigator.onLine)));
        (window as any).addEventListener('unhandledrejection', (event: any) => {
            bugsnagClient.notify(event);
            this.props.dispatch(fileSystemActions.putFile('error_' + Date.now(), JSON.stringify({ type: 'unhandledrejection', event: {
                name: event.name,
                message: event.message,
                stack: event.stack,
                exception: event
            } }), true));
            this.props.dispatch(alertActions.error('An unknown error has occurred. Please press Help > Send Raw Data to TeamValleyWeb and contact Becky.'));
        });

        dispatch(alertActions.onlineStatus((window as any).navigator.onLine));

        history.listen(() => {
            dispatch(alertActions.clear());
        });

        this.flush = this.flush.bind(this);
        this.dumpRaw = this.dumpRaw.bind(this);
        this.toggleDebug = this.toggleDebug.bind(this);

        this.state = {
            debugOpen: false
        };
    }

    toggleDebug() {
        this.setState({
            debugOpen: !this.state.debugOpen
        });
    }

    flush() {
        if (confirm('This will delete all data on the tablet, including unsent data. Are you sure?')) {
            this.props.dispatch(fileSystemActions.flush());
            this.props.dispatch(jobActions.sync(true));
        }
    }

    dumpRaw() {

        if (confirm('Are you sure?')) {
            this.props.dispatch(fileSystemActions.dumpRaw());
        }
    }

    componentDidCatch(error: any, info: any) {
        bugsnagClient.notify(error);
        this.props.dispatch(fileSystemActions.putFile('error_' + Date.now(), JSON.stringify({ error, event: {
                name: info.name,
                message: info.message,
                stack: info.stack,
                exception: info
            } }), true));
        this.props.dispatch(alertActions.error('An unknown error has occurred. Please press Help > Send Raw Data to TeamValleyWeb and contact Becky.'));
    }

    formatQuota(bytes: any, si: boolean = true) {
        var thresh = si ? 1000 : 1024;
        if(Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
            : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while(Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1)+' '+units[u];
    }

    render() {
        return (
            <>
                <Router history={history}>
                    <Switch>
                        <PrivateRoute exact path="/" component={Dashboard} />
                        <PrivateRoute path="/job/:job_id" component={Job} />
                        <PrivateRoute path="/route/:route_id/:week_commencing" component={JobRoute} />
                        <PrivateRoute path="/calendar" component={Calendar} />
                        <Route path="/login" component={Login} />
                        <Route render={() => <p>Not found!</p>} />
                    </Switch>
                </Router>
                <div className={`app-debug ${(this.state.debugOpen ? ' active' : '')}`}>
                    <a onClick={this.toggleDebug}>Help</a>
                    <div>
                        <p>Help Menu</p>
                        <ul>
                            <li><a onClick={this.flush}>Flush Data</a></li>
                            <li><a onClick={this.dumpRaw}>Send Raw Data to TeamValleyWeb</a></li>
                        </ul>
                    </div>
                </div>
                <div className="app-version">Available Storage: {this.formatQuota(this.props.quota_remaining)} of {this.formatQuota(this.props.quota_total)} | {`Build #${this.props.version} - ${this.props.build_date} - ${this.props.environment}`}</div>
            </>
        );
    }
}

function mapStateToProps(state: any) {
    const { alert, fileSystem } = state;
    return {
        alert,
        quota_total: fileSystem.quota_total,
        quota_used: fileSystem.quota_used,
        quota_remaining: fileSystem.quota_remaining,
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };