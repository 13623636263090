import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export class PrivateRoute extends React.Component<any, any> {
    render() {
        let { component: Component, ...rest } = this.props;
        return (
            <Route {...rest} render={props => (
                localStorage.getItem('user_token')
                    ? <Component {...props} />
                    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )} />
        );
    }
}