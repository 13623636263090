import {testResultConstants} from '../_constants';
import {testResultService} from '../_services';
import { alertActions } from './';

export const testResultActions = {
    getFailures,
    getBulkFailures,
    flushFailures
};

function getFailures(site_id: any) {
    return (dispatch: any) => {
        dispatch(request());

        testResultService.getFailures(site_id)
            .then(
                (job: any) => dispatch(success(job)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: testResultConstants.GETFAILURES_REQUEST } }
    function success(failures: any) { return { type: testResultConstants.GETFAILURES_SUCCESS, failures } }
    function failure(error: any) { return { type: testResultConstants.GETFAILURES_FAILURE, error } }
}

function getBulkFailures(site_ids: any, unit_ids: any, force: any = false) {
    return (dispatch: any) => {
        dispatch(request());

        if (site_ids.length <= 0) {
            return dispatch(success([]));
        }

        testResultService.getBulkFailures(site_ids, unit_ids, force)
            .then(
                (job: any) => dispatch(success(job)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: testResultConstants.GETFAILURES_REQUEST } }
    function success(failures: any) { return { type: testResultConstants.GETFAILURES_SUCCESS, failures } }
    function failure(error: any) { return { type: testResultConstants.GETFAILURES_FAILURE, error } }
}

function flushFailures(site_id: any) {
    return (dispatch: any) => {
        dispatch(request());

        testResultService.flushFailures(site_id)
            .then(
                (job: any) => dispatch(success(job)),
                (error: any) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(typeof error === 'string' ? error : error.message));
                }
            );
    };

    function request() { return { type: testResultConstants.FLUSHFAILURES_REQUEST } }
    function success(failures: any) { return { type: testResultConstants.FLUSHFAILURES_SUCCESS, failures } }
    function failure(error: any) { return { type: testResultConstants.FLUSHFAILURES_FAILURE, error } }
}