import React from 'react';
import Logo from '../../assets/images/Logo.png';

import './index.scss';
import {connect} from "react-redux";

class ExternalLayout extends React.Component<any, any> {

    render() {
        const { alert } = this.props;
        return (
            <div className="ExternalLayout">
                <img src={ Logo } alt="" className="img-fluid logo" />
                {alert.message &&
                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                }
                {this.props.children}
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedExternalLayout = connect(mapStateToProps)(ExternalLayout);
export { connectedExternalLayout as ExternalLayout };