import { authHeader } from '../_helpers';
import {handleResponse, fileSystemService} from '../_services';

let w = (window as any);

export const testResultService = {
    getFailures,
    getBulkFailures,
    flushFailures
};

function getFailures(site_id: any) {
    return new Promise((resolve: any, reject: any) => {
        // Attempt to get the local version.
        fileSystemService.getFile(`site_failures_${site_id}.json`).then((file: any) => {
            resolve(file);
        }).catch((error: any) => {
            // If it doesn't exist, check if we are online.
            if (w.navigator.onLine) {
                // Pull the online version.
                const requestOptions: any = {
                    method: 'GET',
                    headers: authHeader()
                };
                fetch(`/api/sites/${site_id}/failures`, requestOptions).then(handleResponse).then((failures: any) => {
                    return fileSystemService.putFile(`site_failures_${site_id}.json`, failures, false);
                }).then(resolve).catch(reject);
            } else {
                resolve([]);
            }
        });
    });
}

function getBulkFailures(site_ids: any, unit_ids: any, force: any = false) {
    return new Promise((resolve: any, reject: any) => {
        if (force) {
            flushFailures(site_ids).then(() => {
                getBulkFailures(site_ids, unit_ids);
            }).catch(reject);
        } else {

            let actions: any = [];
            site_ids.forEach((site_id: any) => {
                actions.push(new Promise((_resolve: any) => {
                    // get already stored failures. If it doesn't exist, queue it to be retrieved.
                    fileSystemService.getFile(`site_failures_${site_id}.json`).then((file: any) => {
                        _resolve(file);
                    }).catch(() => {
                        _resolve(site_id);
                    })
                }));
            });

            Promise.all(actions).then((failures: any) => {
                let existing: any = failures.filter((failure: any) => typeof failure !== 'number'),
                    to_pull: any = failures.filter((failure: any) => typeof failure === 'number');

                if (to_pull.length && w.navigator.onLine) {
                    // Pull the online version.
                    const requestOptions: any = {
                        method: 'POST',
                        headers: authHeader(),
                        body: {
                            site_ids: to_pull,
                            unit_ids: unit_ids,
                            all: 1
                        }
                    };
                    fetch(`/api/sites/failures`, requestOptions).then(handleResponse).then((failures: any) => {
                        let groups: any = {};
                        failures.forEach((failure: any) => {
                            if (!(failure.site_id in groups)) {
                                groups[failure.site_id] = [];
                            }
                            groups[failure.site_id].push(failure);
                        });

                        actions = [];

                        Object.keys(groups).forEach((site_id: any) => {
                            actions.push(fileSystemService.putFile(`site_failures_${site_id}.json`, groups[site_id], false));
                        });

                        Promise.all(actions).then((failures) => {
                            existing = existing.concat(failures);
                            resolve(existing);
                        });

                    }).catch(reject);
                } else {
                    resolve(existing);
                }
            });

        }
    });

}

function flushFailures(site_id: any) {
    return new Promise((resolve: any, reject: any) => {
        site_id = (typeof site_id === 'number' ? [site_id] : site_id);
        let actions: any = [];
        site_id.forEach((id: any) => {
            actions.push(fileSystemService.removeFile(`site_failures_${id}.json`))
        });
        // Attempt to get the local version.
        Promise.all(actions).then(resolve).catch(reject);
    });
}