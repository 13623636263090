import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { jobs } from './jobs.reducer';
import { calendar } from './calendar.reducer';
import { sites } from './sites.reducer';
import { fileSystem } from './fileSystem.reducer';
import { testResults } from './testResults.reducer';

const rootReducer = combineReducers({
    authentication,
    alert,
    jobs,
    fileSystem,
    sites,
    testResults,
    calendar
});

export default rootReducer;