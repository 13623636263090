import { fileSystemConstants } from '../_constants';

export function fileSystem(state = {}, action: any) {
    switch (action.type) {

        //

        case fileSystemConstants.ACCESS_REQUEST :
            return {
                fs: false,
                ready: false,
                requesting: true
            };
        case fileSystemConstants.ACCESS_SUCCESS :
            return {
                fs: action.fs,
                ready: true,
                requesting: false
            };
        case fileSystemConstants.ACCESS_FAILURE :
            return {
                fs: false,
                error: action.error,
                ready: false,
                requesting: false
            };

        //

        case fileSystemConstants.QUOTA_REQUEST :
            return {
                quota_requesting: true,
                quota_ready: false,
                quota_total: 0,
                quota_used: 0,
                quota_remaining: 0
            };
        case fileSystemConstants.QUOTA_SUCCESS :
            return {
                quota_requesting: true,
                quota_ready: false,
                quota_total: action.total,
                quota_used: action.used,
                quota_remaining: action.total - action.used
            };
        case fileSystemConstants.QUOTA_FAILURE :
            return {
                quota_requesting: false,
                quota_ready: false,
                quota_total: 0,
                quota_used: 0,
                quota_remaining: 0,
                quota_error: action.error
            };

        //

        case fileSystemConstants.GET_FILE_REQUEST :
            return {
                file: false,
                requesting: true
            };
        case fileSystemConstants.GET_FILE_SUCCESS :
            return {
                file: action.file,
                requesting: false
            };
        case fileSystemConstants.GET_FILE_FAILURE :
            return {
                error: action.error,
                file: false,
                requesting: false
            };

        //

        case fileSystemConstants.PUT_FILE_REQUEST :
            return {
                requesting: true
            };
        case fileSystemConstants.PUT_FILE_SUCCESS :
            return {
                requesting: false
            };
        case fileSystemConstants.PUT_FILE_FAILURE :
            return {
                error: action.error,
                requesting: false
            };


        //

        case fileSystemConstants.FLUSH_REQUEST :
            return {
                flushing: true
            };
        case fileSystemConstants.FLUSH_SUCCESS :
            return {
                flushing: false,
                flushed: true
            };
        case fileSystemConstants.FLUSH_FAILURE :
            return {
                error: action.error,
                flushing: false,
                flushed: false
            };

        default:
            return state
    }
}